import React, { useState } from 'react';
import { Modal, Input, Button, Alert } from 'antd';
import { useAuthService } from '../../../services/auth.service';
import { OtpPasswordModal } from './OtpPasswordModal';

export function ResetPasswordModal({ visible, onHide }) {
    const [email, setEmail] = useState('');
    const [resetOtpVisible, setResetOtpVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { forgotPassword } = useAuthService();

    const handleSubmit = async () => {
        setIsLoading(true);
        if (email) {
            try {
                const response = await forgotPassword(email);
                if (response?.data.status === 'success') {
                    setShowAlert(false);
                    onHide();
                    setResetOtpVisible(true);
                } else {
                    setShowAlert(true);
                }
            } catch (error) {
                setShowAlert(true);
                console.error("Password reset failed:", error);
            }
        } else {
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const resetForm = () => {
        setEmail('');
        setShowAlert(false);
    };

    return (
        <>
            <Modal
                title={<h2 className="text-xl font-semibold mb-2 text-center">Réinitialiser le mot de passe</h2>}
                visible={visible}
                onCancel={() => {
                    resetForm();
                    onHide();
                }}
                footer={null}
                centered
                style={{padding:'50px'}}
            >
                {showAlert && (
                    <Alert
                        message="Veuillez entrer une adresse e-mail valide."
                        type="warning"
                        showIcon
                        style={{ marginBottom: '16px' }}
                    />
                )}
                <p className="mb-4 text-sm text-center">Veuillez indiquer l'adresse e-mail associée à votre compte Linkyways. Nous vous enverrons un e-mail contenant des instructions pour changer votre mot de passe.</p>

                <div style={{ marginBottom: '16px' }}>
                    <label htmlFor="reset-email" style={{ display: 'block', marginBottom: '8px' }}>
                        E-mail
                    </label>
                    <Input
                        type="email"
                        id="reset-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="vous@example.com"
                    />
                </div>
                <Button
                    type="primary"
                    block
                    onClick={handleSubmit}
                    loading={isLoading}
                >
                    Continuer
                </Button>
            </Modal>
            <OtpPasswordModal email={email} visible={resetOtpVisible} onHide={() => setResetOtpVisible(false)} />
        </>
    );
}


// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { useAuthService } from '../../../services/auth.service';
// import { OtpPasswordModal } from './OtpPasswordModal';
//
// Modal.setAppElement('#root');
//
// export function ResetPasswordModal({ visible, onHide }) {
//     const [email, setEmail] = useState('');
//     const [resetOtpVisible, setReresetOtpVisible] = useState(false);
//     const [showAlert, setShowAlert] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const {forgotPassword} = useAuthService();
//
//     const handleSubmit = async (e) => {
//         setIsLoading(true);
//         e.preventDefault();
//         if (email) {
//             try {
//                 const response = await forgotPassword(email);
//                 // console.log(response, "  ", response?.status);
//                 if (response?.data.status === 'success') {
//                     setIsLoading(false);
//                     setShowAlert(false);
//                     onHide();
//                     setReresetOtpVisible(true);
//                     // console.log(response.message || "E-mail de réinitialisation envoyé avec succès");
//                 } else {
//                     setShowAlert(true);
//                     setIsLoading(false);
//                     // console.log(response?.message || 'Une erreur est survenue');
//                 }
//             } catch (error) {
//                 setShowAlert(true);
//                 setIsLoading(false);
//                 console.error("Échec de la réinitialisation du mot de passe :", error);
//             }
//         } else {
//             // console.log("Le champ e-mail est vide");
//             setShowAlert(true);
//             setIsLoading(false);
//         }
//     };
//
//     const resetForm = () => {
//         setEmail('');
//         setShowAlert(false);
//     };
//
//     return (
//         <>
//             <Modal
//                 isOpen={visible}
//                 onRequestClose={() => {
//                     resetForm();
//                     onHide();
//                 }}
//                 style={{
//                     overlay: {
//                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                     },
//                     content: {
//                         top: '50%',
//                         left: '50%',
//                         right: 'auto',
//                         bottom: 'auto',
//                         transform: 'translate(-50%, -50%)',
//                         width: '90%',
//                         maxWidth: '400px',
//                         padding: '2rem',
//                         borderRadius: '0.5rem',
//                         backgroundColor: 'white',
//                         textAlign: 'center',
//                         boxSizing: 'border-box',
//                     },
//                 }}
//             >
//                 <button
//                     type="button"
//                     onClick={() => {
//                         resetForm();
//                         onHide();
//                     }}
//                     style={{
//                         position: 'absolute',
//                         top: '10px',
//                         right: '10px',
//                         background: 'none',
//                         border: 'none',
//                         fontSize: '24px',
//                         cursor: 'pointer',
//                     }}
//                 >
//                     &times;
//                 </button>
//                 <h2 className="text-xl font-semibold mb-2">Réinitialiser le mot de passe</h2>
//                 {showAlert && (
//                     <div className="bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-2 mb-3" role="alert">
//                         <div className="flex">
//                             <div className="shrink-0">
//                                 <svg className="shrink-0 size-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
//                                     <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
//                                     <path d="M12 9v4"></path>
//                                     <path d="M12 17h.01"></path>
//                                 </svg>
//                             </div>
//                             <div className="ml-2 ms-0">
//                                 <div className="mt-1 text-sm text-yellow-700">
//                                     Veuillez entrer une adresse e-mail valide.
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//                 <p className="mb-4 text-sm">Veuillez indiquer l'adresse e-mail associée à votre compte Linkyways. Nous vous enverrons un e-mail contenant des instructions pour changer votre mot de passe.</p>
//                 <div className="max-w-xs mx-auto mb-4">
//                     <label htmlFor="reset-email" className="block text-sm font-inter text-gray-700 mb-2 text-left">E-mail</label>
//                     <input
//                         type="email"
//                         id="reset-email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         className="py-2 px-3 block w-full border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring"
//                         placeholder="vous@example.com"
//                     />
//                 </div>
//                 <div className="mb-1">
//                     <button
//                         type="button"
//                         className="w-full py-2 px-4 text-white font-medium rounded-lg bg-[#1681FF] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
//                         onClick={handleSubmit}
//                         disabled={isLoading}
//                    >
//                         Continuer
//                     </button>
//                 </div>
//             </Modal>
//             <OtpPasswordModal email={email} visible={resetOtpVisible} onHide={() => setReresetOtpVisible(false)} />
//         </>
//     );
// }
