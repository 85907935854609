import React from 'react';
import { useAuth } from '../services/auth.provider'; // Adjust the path to your actual auth hook
import Logo from '../images/logo.svg'; // Replace with your logo path
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleGoHome = () => {
    if (isAuthenticated) {
      navigate('/ikigai');
    } else {
     navigate('/');
    }
  };

  return (
    <main className="grid min-h-full place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <img src={Logo} alt="Logo" className="mx-auto mb-6 h-16 w-auto" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-[#141943] sm:text-5xl">Page non trouvée</h1>
        <p className="mt-6 text-base leading-7 text-[#141943]">Désolé, nous n'avons pas pu trouver la page que vous recherchez.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={handleGoHome}
            className="rounded-md bg-blue-500 indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Retourner à l'accueil
          </button>
          {/* <a href="#" className="text-sm font-semibold text-gray-900">Contacter le support <span aria-hidden="true">&rarr;</span></a> */}
        </div>
      </div>
    </main>
  );
}

export default ErrorPage;
