import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {Alert} from "antd";
import "../style/Map.css"
const FormationMap = ({ gps, nomEtab }) => {
    let position = null;

    // Parse GPS data if available and valid
    if (gps) {
        const [lat, lng] = gps.split(',').map(coord => parseFloat(coord.trim()));
        if (!isNaN(lat) && !isNaN(lng)) {
            position = [lat, lng];
        }
    }

    // Create a custom marker icon using the SVG
    const customMarkerIcon = L.divIcon({
        className: 'custom-icon',
        html: `
            <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.999 0.749023C11.9891 0.753781 8.14474 2.34883 5.30929 5.18429C2.47383 8.01974 0.878781 11.8641 0.874023 15.874C0.874023 28.8157 14.624 38.5911 15.2095 39.0006C15.441 39.1623 15.7166 39.249 15.999 39.249C16.2814 39.249 16.557 39.1623 16.7886 39.0006C17.374 38.5911 31.124 28.8157 31.124 15.874C31.1193 11.8641 29.5242 8.01975 26.6888 5.18429C23.8533 2.34883 20.009 0.753781 15.999 0.749023ZM16 10.3745C17.0878 10.3745 18.1512 10.6971 19.0557 11.3014C19.9601 11.9058 20.6651 12.7648 21.0814 13.7698C21.4977 14.7748 21.6066 15.8806 21.3944 16.9475C21.1821 18.0144 20.6583 18.9944 19.8891 19.7636C19.1199 20.5328 18.1399 21.0566 17.073 21.2688C16.0061 21.4811 14.9003 21.3721 13.8953 20.9559C12.8903 20.5396 12.0313 19.8346 11.4269 18.9302C10.8226 18.0257 10.5 16.9623 10.5 15.8745C10.5 15.1522 10.6423 14.437 10.9187 13.7697C11.195 13.1024 11.6002 12.4961 12.1109 11.9854C12.6216 11.4747 13.2279 11.0695 13.8952 10.7931C14.5625 10.5167 15.2778 10.3745 16 10.3745Z" fill="#1A1C1E"/>
            </svg>
        `,
        iconSize: [32, 40],
        popupAnchor: [0, -20],
    });

    // Conditionally render the map if valid position is available
    return position ? (
        <MapContainer key={gps} center={position} zoom={13} style={{ height: "400px", width: "100%" }} className="rounded-md">
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customMarkerIcon}>
                <Popup>
                    {nomEtab}
                </Popup>
            </Marker>
        </MapContainer>
    ) : (
        <div className="w-full flex justify-center">
            <Alert
                message="Les coordonnées GPS ne sont pas disponibles."
                type="info"
                showIcon
                style={{ width: "100%", marginBottom: '16px' }}
            />
        </div>    );
};

export default FormationMap;
