import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/auth.provider";

function PrivateRoute({ children }) {
 // const isAuthenticated = localStorage.getItem('access_token'); // Check token in localStorage
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/" replace />;
}

export default PrivateRoute;
