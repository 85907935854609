import React, { useState, useEffect } from "react";
import SmallImg from "../images/handheart.svg";
import SavedMetiersCard from "../utils/SavedMetiersCard";
import { useItemsService } from "../services/ items.service";
import { CryptoService } from "../services/crypte.service";
import LayoutNo from "../utils/LayoutNo";
import SavedFormationCard from "../utils/SavedFormationCard";
import {useFormationService} from "../services/formation.service";
import {Helmet} from "react-helmet";


export default function SavedItems() {
    const [activeTab, setActiveTab] = useState(1);
    const [metiers, setMetiers] = useState([]);
    const decryptedUserId = CryptoService.decryptData(localStorage.getItem('user_id'))
    const [userId] = useState(() => decryptedUserId);
    const { getSavedProfessions, deleteProfession } = useItemsService();

    const { getSavedPostbac, deletePostBac } = useFormationService();
    const [savedItems, setSavedItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSavedItems = async () => {
            try {
                const response = await getSavedPostbac(userId);
                console.log("saved", response.data)
                if (response.success) {
                    setSavedItems(response.data);
                }
            } catch (error) {
                console.error("Error fetching saved items:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSavedItems();
    }, [userId]);

    const handleDelete = async (postbac_id) => {
        try {
            const result = await deletePostBac(userId, postbac_id);
            if (result.success) {
                setSavedItems(prevItems => prevItems.filter(item => item.postbac_id !== postbac_id));
            }
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    useEffect(() => {
        fetchSavedProfessions();
        // document.title = "Default Title";
    }, [userId]);

    const fetchSavedProfessions = async () => {
        if (decryptedUserId) {
            try {
                const response = await getSavedProfessions(decryptedUserId);
                if (response.data && response.data.status === "success") {
                    setMetiers(response.data.data);
                } else {
                    console.error(response.message);
                }
            } catch (error) {
                console.error('Error fetching saved professions:', error);
            }
        }
    };

    const handleDeleteMetier = async (professionId) => {
        if (!decryptedUserId) {
            return;
        }
        try {
            await deleteProfession(decryptedUserId, professionId);
            setMetiers(metiers.filter(metier => metier.profession_id !== professionId));
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };


    return (
        <LayoutNo>
            <Helmet>
                <title>Enregistrements</title>
            </Helmet>
            <div className="w-full md:mt-0 mt-12 p-1">
                <div>
                    {/*<p className="font-bold text-2xl p-2">Éléments sauvegardés</p>*/}
                    <div className="p-2 ">
                        <div className="flex border-bottom-2 border-gray-200">
                            <button
                                className={`py-2 px-4 text-sm text-[#141943] ${activeTab === 1 ? 'border-2 border-white bg-white border-b-0  rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
                                onClick={() => setActiveTab(1)}
                            >
                                Métiers
                            </button>
                            <button
                                className={`py-2 px-4 text-sm text-[#141943] ${activeTab === 2 ? 'border-2 border-white bg-white border-b-0  rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
                                onClick={() => setActiveTab(2)}
                            >
                                Formations
                            </button>
                        </div>
                        <div className="mt-1">
                            {activeTab === 1 && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-2 p-2">
                                    {metiers.length === 0 ? (
                                        <div className="  p-2  text-center ">
                                            <p className="text-lg text-gray-500">Aucun métier enregistré
                                                </p>
                                        </div>
                                    ) : (
                                        metiers.map((metier) => (
                                            <SavedMetiersCard
                                                key={metier.profession_id}
                                                imgSrc={metier.pictact || SmallImg}
                                                smtitle={metier.business_sect || ''}
                                                title={metier.profession || ''}
                                                chips={metier.chips || []}
                                                frompriceDebutant={metier.salary_1 || ''}
                                                topriceDebutant={metier.salary_2 || ''}
                                                frompriceExp={metier.salary_3 || ''}
                                                topriceExp={metier.salary_4 || ''}
                                                metiersId={metier.profession_id}
                                                onDelete={() => handleDeleteMetier(metier.profession_id)}
                                            />
                                        ))
                                    )}
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div className="mt-2 p-2">
                                    <SavedFormationCard
                                        savedItems={savedItems}
                                        loading={loading}
                                        onDelete={handleDelete}
                                    />
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </LayoutNo>
    );
}