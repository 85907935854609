import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Registration from "./components/Registration";
import Guidance from "./pages/Guidance";
import Testing from './pages/Testing';
import SujetsPreferes from './pages/SujetsPreferes';
import SearchSystemFormation from "./pages/SearchSystemFormation";
import SavedItems from "./pages/SavedItems";
import MetierDetailPage from "./pages/MetierDetailPage";
import ErrorPage from './pages/ErrorPage';
import { ProgressProvider } from './context/ProgressContext';
import SearchSystemmetier from "./pages/SearchSystemMetier";
import QuiCeQueIkigai from "./pages/QuiCeQueIkigai";
import PrivateRoute from './utils/routes/PrivateRoute';
import { AuthProvider } from './services/auth.provider';
import Cgu from "./pages/cgu/CguPage";
import MentionsL from "./pages/cgu/MentionsL";
import FormationDetailPage from "./pages/FormationDetailPage";
import IsAccessibleRoute from './utils/routes/IsAccessibleRoute';
import PublicRoute from "./utils/routes/PublicRoute";
import CookieConsent from "./components/cookie/Cookie";
import GoogleTagManagerNoscript from "./components/cookie/GoogleTagManagerNoScript";
import GoogleTagManagerHead from "./components/cookie/GoogleTagManager";
import StaticSearch from "./SeoStaticSearchEnginPages/searchPages/StaticSearch";
import Apropos from "./pages/Apropos";
import Home from "./pages/Home";
import AproposPage from "./pages/AproposPage";
import ParcourSupPage from "./pages/ParcourSupPage";

function App() {

  return (
    <AuthProvider>
      <ProgressProvider>
          <GoogleTagManagerHead />
          <CookieConsent />
          <Routes>
            <Route path="/public/test-orientation" element={<Testing />} />
            <Route path="/conditions_generales" element={<Cgu />} />
            <Route path="/mentions_legales" element={<MentionsL />} />
            <Route path="/public/inscription" element={<Registration />} />
            <Route path="/metiers" element={<SearchSystemmetier />} />
            <Route path="/metiers/:professionName/:professionId" element={<MetierDetailPage />} />
            <Route path="/formation/:schoolName/:formationName/:formationId" element={<FormationDetailPage />} />
            <Route path="/ikigai" element={<QuiCeQueIkigai />} />
            <Route path="/faq" element={<Apropos />} />
            <Route path="/tout-savoir-sur-parcoursup" element={<AproposPage />} />
            <Route path="/sup" element={<ParcourSupPage />} />
            <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<PublicRoute>< Home/></PublicRoute>} />
            <Route index path="/formations" element={< SearchSystemFormation/>} />

            <Route path="/public/test-orientation/sujets" element={
                  <IsAccessibleRoute>
                      < SujetsPreferes />
                  </IsAccessibleRoute>
            } />

            <Route path="/orientation"
              element={
                <PrivateRoute> 
                  <Guidance /> 
                </PrivateRoute>
              } 
            />

          <Route path="/enregistrements/:userId"
            element={
              <PrivateRoute> 
                <SavedItems /> 
              </PrivateRoute>
            } 
          />

            <Route path="/formations/bts-commerce-alternance" element={<PublicRoute><StaticSearch keyword={"commerce"} studyLevel={7} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/bts-finance-comptabilite" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={5}  /></PublicRoute>}/>
            <Route path="/formations/bts-finance-comptabilite-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={5} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/bts-droit-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={6} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/bts-droit" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={6}  /></PublicRoute>}/>
            <Route path="/formations/bts-art" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={4}  /></PublicRoute>}/>
            <Route path="/formations/bts-agriculture-environnement" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={9}  /></PublicRoute>}/>
            <Route path="/formations/bts-agriculture-environnement-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={9} alternance={1}  /></PublicRoute>}/>
            <Route path="/formations/bts-social" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={7}  keyword={"social"}  /></PublicRoute>}/>
            <Route path="/formations/bts-social-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={7}  keyword={"social"} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/bts-sante" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={10} /></PublicRoute>}/>
            <Route path="/formations/bts-sante-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={10}   alternance={1} /></PublicRoute>}/>


            <Route path="/formations/licence-but-comptabilite" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={5}  keyword={"comptabilite"} /></PublicRoute>}/>
            <Route path="/formations/Licence-but-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={5} alternance={1} keyword={"comptabilite"} /></PublicRoute>}/>
            <Route path="/formations/licence-but-informatique" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={1}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-informatique-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={1} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/licence-but-droit-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={6} alternance={1} /></PublicRoute>}/>
            <Route path="/formations/licence-but-droit" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={6}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-art" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={4} keyword={"art"}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-design" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={4} keyword={"design"}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-environnement" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={9} keyword={"environnement"}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-environnement-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={9} alternance={1} keyword={"environnement"}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-sport" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={7}  keyword={"sport"}  /></PublicRoute>}/>
            <Route path="/formations/licence-but-sante" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={10} /></PublicRoute>}/>
            <Route path="/formations/licence-but-sante-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={10}   alternance={1} /></PublicRoute>}/>


            <Route path="/formations/dn-made" element={<PublicRoute><StaticSearch  keyword={"dn made"}  /></PublicRoute>}/>


              <Route path="/formations/infirmier" element={<PublicRoute><StaticSearch  alternance={1} keyword={"infirmier"} /></PublicRoute>}/>
            <Route path="/formations/licence-economie" element={<PublicRoute><StaticSearch keyword={"economie"} studyLevel={8} /></PublicRoute>}/>
            <Route path="/formations/bts-commerce" element={<PublicRoute><StaticSearch keyword={"commerce"} studyLevel={7} title={"bts-commerce"} metaDescription={"bts-commerce meta"}/></PublicRoute>}/>



          </Routes>
        <GoogleTagManagerNoscript />
      </ProgressProvider>
    </AuthProvider>
  );
}

export default App;
