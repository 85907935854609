import React, { useState } from "react";
import { Modal, Input, Button, message, notification } from "antd";
import { useUserService } from "../../services/userforms.service";

const ContactApprovalModal = ({ isVisible, onClose, userId, userEmail, onContactButtonHide }) => {
    const { saveAppointment } = useUserService();
    const [selectedTime, setSelectedTime] = useState([]);
    const [phone, setPhone] = useState("+33");
    const [messageInput, setMessageInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [phoneError, setPhoneError] = useState("");

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (/^\+?\d*$/.test(value)) {
            setPhone(value);
            setPhoneError("");
        } else {
            setPhoneError("Le numéro de téléphone doit être au format international (ex : +33...).");
        }
    };

    const timeOptions = [
        "8:00 AM - 10:00 AM",
        "10:00 AM - 12:00 PM",
        "12:00 PM - 2:00 PM",
        "2:00 PM - 4:00 PM",
        "4:00 PM - 6:00 PM",
        "6:00 PM - 8:00 PM",
    ];

    const toggleTimeSelection = (time) => {
        setSelectedTime((prev) =>
            prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time]
        );
    };

    const handleSubmit = async () => {
        if (!/^\+\d{10,15}$/.test(phone)) {
            setPhoneError("Veuillez entrer un numéro de téléphone valide (avec indicatif international, ex: +33...).");
            return;
        }
        setLoading(true);
        const appointmentData = {
            user_id: userId,
            user_email: userEmail,
            phone_number: phone,
            scheduled_date: selectedTime,
            message: messageInput,
        };

        try {
            await saveAppointment(appointmentData);
            notification.success({
                message: "Demande enregistrée",
                placement: "topRight",
            });
            localStorage.setItem("isContactButtonVisible", false);
            onContactButtonHide();
            onClose();
            setSelectedTime([]);
            setPhone("");
            setMessageInput("");
        } catch (error) {
            message.error("Oops, veuillez réessayer dans quelques instants.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={
                <p className="text-[15px] font-bold text-[#141943] text-left">
                    Quel est le meilleur moment pour vous contacter ?
                </p>
            }
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            className="rounded-lg"
            width={800} // Adjust width for a larger modal
        >
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8">
                {/* Left Column - Time Options */}
                <div className="flex-1 space-y-2">
                    <div className="space-y-2 my-3">
                        {timeOptions.map((time) => (
                            <div
                                key={time}
                                className={`bg-white border-2 border-[#D0DBE7] rounded-lg flex items-center relative p-1 cursor-pointer ${
                                    selectedTime.includes(time) ? "border-2 border-green-500" : ""
                                }`}
                                onClick={() => toggleTimeSelection(time)}
                            >
                                <span className="font-small text-[#141943] mx-auto">{time}</span>
                                {selectedTime.includes(time) && (
                                    <div className="absolute right-4">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
                                                fill="#009621"
                                            />
                                            <path
                                                d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Divider for large screens */}
                <div className="hidden sm:block sm:w-px bg-gray-300"></div>

                {/* Right Column - Phone & Message */}
                <div className="flex-1 space-y-4">
                    <div className="mb-1">
                        <label className="block text-[#141943] font-small mb-2">
                            Veuillez indiquer votre numéro de téléphone.
                        </label>
                        <Input
                            placeholder="+33 6 12 34 56 78"
                            value={phone}
                            onChange={handlePhoneChange}
                            className="border-gray-300 bg-gray-200 rounded-md"
                        />
                        {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
                    </div>

                    <div className="mb-1">
                        <label className="block text-[#141943] font-small mb-1">
                            Message (Optionnel)
                        </label>
                        <Input.TextArea
                            placeholder="Vous pouvez préciser vos besoins ou questions pour que l'échange soit plus ciblé."
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                            className="border-gray-300 bg-gray-200 rounded-md placeholder-gray-500"
                            rows={3}
                        />
                    </div>

                    <p className="text-[#141943] font-small text-sm text-left mb-2">
                        {/*10 minutes pour éclairer vos choix et répondre à vos questions.*/}
                        Un conseiller vous enverra un message au moins 24h avant votre appel pour vous proposer des créneaux de 10min.
                    </p>

                    <Button
                        type="primary"
                        className="w-full bg-green-500 hover:bg-green-600 border-none rounded-lg p-3 text-white"
                        onClick={handleSubmit}
                        disabled={!phone || selectedTime.length === 0 || loading}
                        loading={loading}
                    >
                        Planifier mon appel
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ContactApprovalModal;


// import React, { useState } from "react";
// import { Modal, Radio, Input, Button, message } from "antd";
// import { format, addDays, subDays, isToday, parse } from "date-fns";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import { useUserService } from "../../services/userforms.service";
// import { fr } from "date-fns/locale";
//
// const ContactApprovalModal = ({ isVisible, onClose, userId, userEmail }) => {
//     const { saveAppointment } = useUserService();
//     const [selectedTime, setSelectedTime] = useState("");
//     const [phone, setPhone] = useState("");
//     const [currentDate, setCurrentDate] = useState(new Date());
//     const [loading, setLoading] = useState(false);
//     const [phoneError, setPhoneError] = useState(""); // To store validation error
//
//     const handlePhoneChange = (e) => {
//         const value = e.target.value;
//         if (/^\d*$/.test(value)) {
//             setPhone(value);
//             setPhoneError(""); // Clear the error if valid
//         } else {
//             setPhoneError("Le numéro de téléphone doit contenir uniquement des chiffres."); // Show error
//         }
//     };
//
//     const handleNextDay = () => {
//         setCurrentDate((prev) => addDays(prev, 1));
//     };
//
//     const handlePrevDay = () => {
//         setCurrentDate((prev) => subDays(prev, 1));
//     };
//
//     const isCurrentDay = isToday(currentDate);
//
//     const timeOptions = [
//         "8:00 AM – 10:00 AM",
//         "10:00 AM – 12:00 PM",
//         "12:00 PM – 2:00 PM",
//         "2:00 PM – 4:00 PM",
//         "4:00 PM – 6:00 PM",
//         "6:00 PM – 8:00 PM",
//     ];
//
//     const isTimeInPast = (time) => {
//         if (!isCurrentDay) return false;
//
//         const now = new Date();
//         const [startTime] = time.split(" – ");
//         const parsedTime = parse(startTime, "h:mm a", new Date());
//         return parsedTime < now;
//     };
//
//     const handleSubmit = async () => {
//         if (!/^\d{10,15}$/.test(phone)) {
//             setPhoneError("Veuillez entrer un numéro de téléphone valide (10 à 15 chiffres)");
//             return;
//         }
//
//         setLoading(true);
//         const scheduledDate = format(currentDate, "yyyy-MM-dd") + " " + selectedTime;
//
//         const appointmentData = {
//             user_id: userId,
//             user_email: userEmail,
//             phone_number: phone,
//             scheduled_date: scheduledDate,
//         };
//
//         try {
//             await saveAppointment(appointmentData);
//             message.success("Nous avons bien reçu votre demande.");
//             onClose();
//             setSelectedTime("")
//             setPhone("")
//         } catch (error) {
//             message.error( "Oops, veuillez réessayer dans quelques instants.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     return (
//         <Modal
//             title={<p className="font-bold font-poppins text-[#141943] text-center">Quel est le meilleur moment pour vous contacter ?</p>}
//             visible={isVisible}
//             onCancel={onClose}
//             footer={null}
//             className="rounded-lg"
//         >
//             <div className="space-y-1 px-6">
//                 <div className="flex items-center justify-between w-full max-w-md mx-auto">
//                     <button
//                         onClick={handlePrevDay}
//                         className={`text-gray-600 hover:text-gray-800 focus:outline-none ${
//                             isCurrentDay ? "cursor-not-allowed text-gray-400" : ""
//                         }`}
//                         disabled={isCurrentDay}
//                     >
//                         <LeftOutlined />
//                     </button>
//                     <div className="flex-1 text-center">
//                         <h4 className="text-[#141943] font-semibold font-poppins truncate">
//                             {format(currentDate, "EEEE, MMMM d", { locale: fr })}
//                         </h4>
//                     </div>
//                     <button
//                         onClick={handleNextDay}
//                         className="text-gray-600 hover:text-gray-800 focus:outline-none"
//                     >
//                         <RightOutlined />
//                     </button>
//                 </div>
//                 <div className="space-y-1 my-3">
//                     {timeOptions.map((time) => {
//                         const disabled = isTimeInPast(time);
//                         return (
//                             <div
//                                 key={time}
//                                 className={`bg-white border-2 border-[#D0DBE7] rounded-lg flex items-center relative p-1 cursor-pointer ${
//                                     selectedTime === time ? "border-2 border-green-500" : ""
//                                 } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
//                                 onClick={() => !disabled && setSelectedTime(time)}
//                             >
//                                 <span className="font-small text-[#141943] mx-auto">{time}</span>
//                                 {selectedTime === time && !disabled && (
//                                     <div className="absolute right-4">
//                                         <svg
//                                             width="20"
//                                             height="20"
//                                             viewBox="0 0 20 20"
//                                             fill="none"
//                                             xmlns="http://www.w3.org/2000/svg"
//                                         >
//                                             <path
//                                                 d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
//                                                 fill="#009621"
//                                             />
//                                             <path
//                                                 d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
//                                                 stroke="white"
//                                                 strokeWidth="2"
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                             />
//                                         </svg>
//                                     </div>
//                                 )}
//                             </div>
//                         );
//                     })}
//                 </div>
//
//                 <div className="mb-1">
//                     <label className="block text-[#141943] font-small mb-2">
//                         Veuillez fournir votre numéro de téléphone afin que le conseiller puisse vous contacter à l'heure de votre choix.                    </label>
//                     <Input
//                         placeholder="Enter your phone number"
//                         value={phone}
//                         onChange={handlePhoneChange}
//                         className="border-gray-300 bg-gray-200 rounded-md"
//                     />
//                     {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
//                 </div>
//                 <Button
//                     type="primary"
//                     className="w-full bg-green-500 hover:bg-green-600 border-none rounded-lg p-3 text-white"
//                     onClick={handleSubmit}
//                     disabled={!phone || !selectedTime || loading}
//                     loading={loading}
//                 >
//                     Envoyer
//                 </Button>
//             </div>
//         </Modal>
//     );
// };
//
// export default ContactApprovalModal;