import {useEffect, useState, useRef} from "react";
import LayoutNo from "../utils/LayoutNo";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {useFormationService} from "../services/formation.service";
import AproposSkeleton from "../utils/skeletons/AproposSkeleton";
import {Helmet} from "react-helmet";
import React from "react";
import {Link} from "react-router-dom"
import {Button} from "antd";
import LoginModal from "../components/modal/auth/LoginModal";
import ContactApprovalModal from "../components/contactApprovalModal/ContactApprovalModal";
import {CryptoService} from "../services/crypte.service";
import {useUserService} from "../services/userforms.service";
import {useAuth} from "../services/auth.provider";


export default function Apropos() {
    const [openIndex, setOpenIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSection, setSelectedSection] = useState("Tout savoir sur Parcoursup");

    const [isModalApproved, setIsModalApproved] = useState(false);
    const [userId, setUserId] = useState(null);
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));
    const {isAuthenticated} = useAuth();
    const storedVisibility = localStorage.getItem('isContactButtonVisible');
    const {checkAppointment} = useUserService();

    const [isContactButtonVisible, setIsContactButtonVisible] = useState(
        storedVisibility ? JSON.parse(storedVisibility) : false
    );
    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (isAuthenticated && user) {
            const decryptedId = CryptoService.decryptData(user);
            setUserId(decryptedId);
            checkAppointment(decryptedId)
                .then(canMakeAppointment => {
                    setIsContactButtonVisible(canMakeAppointment);
                    localStorage.setItem('isContactButtonVisible', JSON.stringify(canMakeAppointment));
                })
                .catch(error => {
                    console.error("Error checking appointment:", error);
                    setIsContactButtonVisible(false);
                });
        } else {
            setIsContactButtonVisible(false);
            localStorage.removeItem('isContactButtonVisible');
        }
    }, [isAuthenticated, userEmail]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const onContactButtonHide = () => {
        setIsContactButtonVisible(false);
    };

    const {getFaqs} = useFormationService();

    const accordionRefs = useRef([]);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await getFaqs();
                setFaqs(response.data.data);
                console.log(response.data.data)
                setLoading(false);
            } catch (error) {
                setError("Error fetching FAQs");
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);

    const toggleAccordion = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
            setTimeout(() => {
                accordionRefs.current[index]?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }, 100);
        }
    };
    const renderContent = (responses, types) => {
        return responses.map((response, index) => {
            if (!response) return null;

            const type = types[index];
            const commonClass = "mb-2  text-md leading-relaxed font-poppins text-justify";

            switch (type) {
                case "sous-titre":
                    return (
                        <p key={index} className={`${commonClass} font-bold text-[#141943]  pt-3 pb-1 ml-2`}>
                            {response}
                        </p>
                    );
                case "txt":
                    return (
                        <p key={index} className={`ml-4 ${commonClass}`}>
                            {response}
                        </p>
                    );
                case "list":
                    return (
                        <ul key={index} className={`list-disc ml-6  ${commonClass}`}>
                            {response.split(";").map((item, i) => (
                                <li key={i}>{item.trim()}</li>
                            ))}
                        </ul>
                    );
                case "astuce":
                    return (
                        <div
                            key={index}
                            className="p-2 ml-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 my-4"
                        >
                            {response}
                        </div>
                    );
                case "conseil":
                    return (
                        <div
                            key={index}
                            className="p-2 ml-4  bg-green-100 border-l-4 border-green-500 text-green-700 my-4"
                        >
                            <p className="text-md">{response}</p>
                        </div>
                    );
                case "txt-ul":
                    return (
                        <p key={index} className={`${commonClass} ml-4 underline`}>
                            {response}
                        </p>
                    );
                case "txt-blue":
                    return (
                        <p key={index} className={`${commonClass} ml-4 font-bold text-blue-500`}>
                            {response}
                        </p>
                    );
                case "list_flèche":
                    return (
                        <ul key={index} className={`list-none ml-4   ${commonClass}`}>
                            {response.split(";").map((item, i) => (
                                // <li key={i} className="before:content-['→'] before:mr-2">
                                <li key={i} className=" before:mr-2">
                                    {item.trim()}
                                </li>
                            ))}
                        </ul>
                    );
                case "txt-green":
                    return (
                        <p key={index} className={`${commonClass} ml-4 text-green-500`}>
                            {response}
                        </p>
                    );
                case "lien":
                    const [displayText, link] = response.split("|").map((part) => part.trim());

                    return (
                        <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${commonClass}`}
                        >
                            <p className="text-blue-500 underline">{displayText || "Click here"}</p>
                        </a>
                    );

                case "list_num":
                    return (
                        <ol key={index} className={`list-decimal ml-8 ${commonClass}`}>
                            {response.split(";").map((item, i) => (
                                <li key={i}>{item.trim()}</li>
                            ))}
                        </ol>
                    );
                default:
                    return (
                        <p key={index} className={`${commonClass}`}>
                            {response}
                        </p>
                    );
            }
        });
    };


    const filteredFaqs = faqs.filter((faq) => faq.section === selectedSection);

    if (loading) {
        return (
            <AproposSkeleton/>
        );
    }

    if (error) return <p>{error}</p>;

    return (
        <LayoutNo>
            <Helmet>
                <title>Tout savoir sur Parcoursup</title>
            </Helmet>
            <div className="flex flex-col md:flex-row gap-4 p-3 md:mt-1 mt-6">
                {/* Left Section */}
                <div className="md:w-1/4">
                    {/*<h2 className="text-lg font-bold mb-4">Sections</h2>*/}
                    <div className="space-y-2">
                        {/*{Array.from(new Set(faqs.map((faq) => faq.section))).map((section, index) => (*/}
                            <Link to={"/faq"}>
                            <button
                                className={`block w-full text-left   py-2 border-b-2 md:text-lg text-md text-black font-semibold `}
                            >
                                À propos
                            </button>
                            </Link>
                                <button
                                    className={`block w-full text-left   py-2 border-b-2 md:text-lg text-md text-blue-600  font-bold`}
                                >
                                    Tout savoir sur Parcoursup
                                </button>
                        {/*))}*/}
                    </div>
                </div>

                {/* Right Section */}
                <div className="md:w-3/4 w-full">
                    {filteredFaqs.map((faq, index) => (
                        <div key={index}
                             className="border-1 bg-white shadow-md rounded-md border-gray-200 mb-2 p-2"
                             ref={(el) => (accordionRefs.current[index] = el)}
                        >
                            <button
                                className="flex justify-between items-center w-full py-2 md:px-6 px-2 text-left font-bold text-[#141943] md:text-lg text-md"
                                onClick={() => toggleAccordion(index)}
                            >
                                {faq.question}
                                {/*<span*/}
                                {/*    className="text-gray-900 font-bold text-2xl border rounded-full p-1 flex items-center justify-center">*/}
                                {/*    {openIndex === index ? (*/}
                                {/*        <MinusOutlined className="text-xl"/>*/}
                                {/*    ) : (*/}
                                {/*        <PlusOutlined className="text-xl"/>*/}
                                {/*    )}*/}
                                {/*</span>*/}
                            </button>
                            {/*{openIndex === index && (*/}
                                <div className="py-2 md:px-6 px-2  md:text-sm text-xs">
                                    {renderContent(
                                        [
                                            faq.response_1,
                                            faq.response_2,
                                            faq.response_3,
                                            faq.response_4,
                                            faq.response_5,
                                            faq.response_6,
                                            faq.response_7,
                                            faq.response_8,
                                            faq.response_9,
                                            faq.response_10,
                                            faq.response_11,
                                            faq.response_12,
                                            faq.response_13,
                                            faq.response_14,
                                            faq.response_15,
                                            faq.response_16,
                                            faq.response_17,
                                            faq.response_18,
                                            faq.response_19,
                                            faq.response_20,
                                            faq.response_21,
                                            faq.response_22,
                                            faq.response_23,
                                        ],
                                        [
                                            faq.type_1,
                                            faq.type_2,
                                            faq.type_3,
                                            faq.type_4,
                                            faq.type_5,
                                            faq.type_6,
                                            faq.type_7,
                                            faq.type_8,
                                            faq.type_9,
                                            faq.type_10,
                                            faq.type_11,
                                            faq.type_12,
                                            faq.type_13,
                                            faq.type_14,
                                            faq.type_15,
                                            faq.type_16,
                                            faq.type_17,
                                            faq.type_18,
                                            faq.type_19,
                                            faq.type_20,
                                            faq.type_21,
                                            faq.type_22,
                                            faq.type_23,
                                        ]
                                    )}
                                    {faq.cta && (
                                        <div className="mt-4 flex justify-end">
                                            {/* Show the Contact Us button only if the conditions are met */}
                                            {faq.cta === "/contact" &&
                                            ((isContactButtonVisible && isAuthenticated) || !isAuthenticated) ? (
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={() => {
                                                        if (isAuthenticated) {
                                                            setIsModalApproved(true);
                                                        } else {
                                                            showModal();
                                                        }
                                                    }}
                                                    className="bg-green-500"
                                                >
                                                    Être conseillé(e)
                                                </Button>
                                            ) : faq.cta !== "/contact" ? (
                                                // Show the "voir plus" button for all other cases
                                                <Link to={faq.cta}>
                                                    <Button type="primary" className=" w-full font-semibold font-poppins text-md gap-3 px-5 py-3 ">

                                                    {faq.button}
                                                    </Button>
                                                </Link>
                                            ) : null}
                                        </div>
                                    )}

                                </div>

                            {/*)}*/}
                        </div>
                    ))}
                </div>
            </div>
            <ContactApprovalModal
                userId={userId}
                userEmail={userEmail}
                isVisible={isModalApproved}
                onClose={() => setIsModalApproved(false)}
                onContactButtonHide={onContactButtonHide}
            />
            <LoginModal visible={isModalVisible} onHide={hideModal}/>

        </LayoutNo>
    );
}


// import React, {useEffect, useState, useRef} from "react";
// import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
// import {Link} from "react-router-dom";
// import {useFormationService} from "../services/formation.service";
// import AproposSkeleton from "../utils/skeletons/AproposSkeleton";
// import LayoutNo from "../utils/LayoutNo";
//
// const AproposPage = () => {
//     const [openIndex, setOpenIndex] = useState(null);
//     const [faqs, setFaqs] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [selectedSection, setSelectedSection] = useState("À propos");
//
//     const {getFaqs} = useFormationService();
//     const accordionRefs = useRef([]);
//
//     useEffect(() => {
//         const fetchFaqs = async () => {
//             try {
//                 const response = await getFaqs();
//                 setFaqs(response.data.data);
//                 setLoading(false);
//             } catch (error) {
//                 setError("Error fetching FAQs");
//                 setLoading(false);
//             }
//         };
//         fetchFaqs();
//     }, []);
//
//     const toggleAccordion = (index) => {
//         if (openIndex === index) {
//             setOpenIndex(null);
//         } else {
//             setOpenIndex(index);
//             setTimeout(() => {
//                 accordionRefs.current[index]?.scrollIntoView({
//                     behavior: "smooth",
//                     block: "start",
//                 });
//             }, 100);
//         }
//     };
//
//     const renderContent = (responses, types) => {
//         return responses.map((response, index) => {
//             if (!response) return null;
//
//             const type = types[index];
//             const commonClass = "mb-2 text-md leading-relaxed font-poppins text-justify";
//
//             switch (type) {
//                 case "sous-titre":
//                     return (
//                         <p key={index} className={`${commonClass} font-bold text-[#141943] pt-3 pb-1 ml-2`}>
//                             {response}
//                         </p>
//                     );
//                 case "txt":
//                     return (
//                         <p key={index} className={`ml-4 ${commonClass}`}>
//                             {response}
//                         </p>
//                     );
//                 case "list":
//                     return (
//                         <ul key={index} className={`list-disc ml-6 ${commonClass}`}>
//                             {response.split(";").map((item, i) => (
//                                 <li key={i}>{item.trim()}</li>
//                             ))}
//                         </ul>
//                     );
//                 case "astuce":
//                     return (
//                         <div key={index} className="p-2 ml-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700 my-4">
//                             {response}
//                         </div>
//                     );
//                 case "conseil":
//                     return (
//                         <div key={index}
//                              className="p-2 ml-4 bg-green-100 border-l-4 border-green-500 text-green-700 my-4">
//                             <p className="text-md">{response}</p>
//                         </div>
//                     );
//                 case "txt-ul":
//                     return (
//                         <p key={index} className={`${commonClass} ml-4 underline`}>
//                             {response}
//                         </p>
//                     );
//                 case "txt-blue":
//                     return (
//                         <p key={index} className={`${commonClass} ml-4 font-bold text-blue-500`}>
//                             {response}
//                         </p>
//                     );
//                 case "list_flèche":
//                     return (
//                         <ul key={index} className={`list-none ml-4 ${commonClass}`}>
//                             {response.split(";").map((item, i) => (
//                                 <li key={i} className="before:mr-2">{item.trim()}</li>
//                             ))}
//                         </ul>
//                     );
//                 case "txt-green":
//                     return (
//                         <p key={index} className={`${commonClass} ml-4 text-green-500`}>
//                             {response}
//                         </p>
//                     );
//                 case "lien":
//                     const [displayText, link] = response.split("|").map((part) => part.trim());
//                     return (
//                         <a key={index} href={link} target="_blank" rel="noopener noreferrer"
//                            className={`${commonClass}`}>
//                             <p className="text-blue-500 underline">{displayText || "Click here"}</p>
//                         </a>
//                     );
//                 case "list_num":
//                     return (
//                         <ol key={index} className={`list-decimal ml-8 ${commonClass}`}>
//                             {response.split(";").map((item, i) => (
//                                 <li key={i}>{item.trim()}</li>
//                             ))}
//                         </ol>
//                     );
//                 default:
//                     return (
//                         <p key={index} className={`${commonClass}`}>
//                             {response}
//                         </p>
//                     );
//             }
//         });
//     };
//
//     const filteredFaqs = faqs.filter((faq) => faq.section === selectedSection);
//
//     if (loading) {
//         return <AproposSkeleton/>;
//     }
//
//     if (error) return <p>{error}</p>;
//
//     return (
//         <LayoutNo>
//             <div className="md:w-3/4 w-full md:mt-2 mt-7 p-2">
//                 {filteredFaqs.map((faq, index) => (
//                     <div
//                         key={index}
//                         className="border-1 bg-[#F8FBFF] rounded-md border-gray-200 mb-2 p-2"
//                         ref={(el) => (accordionRefs.current[index] = el)}
//                     >
//                         <button
//                             className="flex justify-between items-center w-full py-2 md:px-6 px-2 text-left font-bold text-[#141943] md:text-lg text-md"
//                             onClick={() => toggleAccordion(index)}
//                         >
//                             {faq.question}
//                             <span
//                                 className="text-gray-900 font-bold text-2xl border rounded-full p-1 flex items-center justify-center">
//               {openIndex === index ? (
//                   <MinusOutlined className="text-xl"/>
//               ) : (
//                   <PlusOutlined className="text-xl"/>
//               )}
//             </span>
//                         </button>
//                         {/*{openIndex === index && (*/}
//                             <div className="py-2 md:px-6 px-2 md:text-sm text-xs">
//                                 {renderContent(
//                                     [
//                                         faq.response_1,
//                                         faq.response_2,
//                                         faq.response_3,
//                                         faq.response_4,
//                                         faq.response_5,
//                                         faq.response_6,
//                                         faq.response_7,
//                                         faq.response_8,
//                                         faq.response_9,
//                                         faq.response_10,
//                                         faq.response_11,
//                                         faq.response_12,
//                                         faq.response_13,
//                                         faq.response_14,
//                                         faq.response_15,
//                                         faq.response_16,
//                                         faq.response_17,
//                                         faq.response_18,
//                                         faq.response_19,
//                                         faq.response_20,
//                                         faq.response_21,
//                                         faq.response_22,
//                                         faq.response_23,
//                                     ],
//                                     [
//                                         faq.type_1,
//                                         faq.type_2,
//                                         faq.type_3,
//                                         faq.type_4,
//                                         faq.type_5,
//                                         faq.type_6,
//                                         faq.type_7,
//                                         faq.type_8,
//                                         faq.type_9,
//                                         faq.type_10,
//                                         faq.type_11,
//                                         faq.type_12,
//                                         faq.type_13,
//                                         faq.type_14,
//                                         faq.type_15,
//                                         faq.type_16,
//                                         faq.type_17,
//                                         faq.type_18,
//                                         faq.type_19,
//                                         faq.type_20,
//                                         faq.type_21,
//                                         faq.type_22,
//                                         faq.type_23,
//                                     ]
//                                 )}
//                                 {!faq.link && (
//                                     <div className="mt-4 flex justify-end">
//                                         <Link
//                                             to={faq.cta}
//                                             className="inline-block px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700"
//                                         >
//                                             Learn More
//                                         </Link>
//                                     </div>
//                                 )}
//                             </div>
//                         {/*)}*/}
//                     </div>
//                 ))}
//             </div>
//         </LayoutNo>
//     );
// };
//
// export default AproposPage;
