const FaqIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4578_22789)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.17168 18.4767L6.50242 22.5L10 18.6772H15.4975C17.9794 18.6772 19.995 16.662 19.995 14.1798V5.18495C19.995 2.70273 17.9794 0.6875 15.4975 0.6875H6.50242C4.02054 0.6875 2.00488 2.70273 2.00488 5.18495V14.1798C2.00488 16.1988 3.3382 17.9089 5.17168 18.4767ZM18.9006 5.84353C18.9006 3.604 17.0826 1.78574 14.8431 1.78574C14.7245 1.78574 14.6282 1.88199 14.6282 2.00058C14.6282 2.11918 14.7245 2.21543 14.8431 2.21543C16.8454 2.21543 18.4709 3.84115 18.4709 5.84353C18.4746 6.12425 18.8962 6.127 18.9006 5.84353ZM13.8024 1.78574H12.6388C12.3567 1.79051 12.3561 2.21053 12.6388 2.21543H13.8024C14.0844 2.21066 14.085 1.79064 13.8024 1.78574Z" fill="#141943"/>
            </g>
            <defs>
                <clipPath id="clip0_4578_22789">
                    <rect width="22" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
export default FaqIcon;

