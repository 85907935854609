
import React, { useState, useEffect } from "react";
import CustomCheckbox from "./FormationComponents/CustomCheckbox";
import MultiCustomSelect from "./dropDown/MultiCustomSelect";
import { useFormationService } from "../services/formation.service";
import {useAuth} from "../services/auth.provider";

export default function FormationFilters({
                                             checkboxValues,
                                             onCheckboxChange,
                                             onSelectAccessChange,
                                             onSelectAreaChange,
                                             onSelectStudLevel,
                                             clearFilters,
                                             selectedStudLevelIds,
                                             selectedAreaIds,
                                             selectedAccessRateIds,
                                         }) {
    const { getAreaStudies, getAccessRate, getTrainingType } = useFormationService();

    const [area, setArea] = useState([]);
    const [rate, setAccessRate] = useState([]);
    const [training, setTraining] = useState([]);
    const [initialStudLevelSelected, setInitialTrainingSelected] = useState([]);
    const [initialAreaSelected, setInitialAreaSelected] = useState([]);
    const [initialAccessSelected, setInitialAccessSelected] = useState([]);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        // Consolidated data fetch function to avoid multiple calls
        const fetchData = async () => {
            try {
                const [areaResponse, accessRateResponse, trainingTypeResponse] = await Promise.all([
                    getAreaStudies(),
                    getAccessRate(),
                    getTrainingType(),
                ]);

                setArea(areaResponse.data.data);
                setAccessRate(accessRateResponse.data.data);
                //console.log("niveau",trainingTypeResponse.data.data)

                const filteredTrainingData = trainingTypeResponse.data.data.filter(item => item.etude_sup > 0);
                //console.log("niveau",filteredTrainingData)
                setTraining(filteredTrainingData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Only called on initial render
    }, []);


    useEffect(() => {
        if (training?.length > 0 && selectedStudLevelIds?.length > 0) {
            const selected = training.filter(option =>
                selectedStudLevelIds.includes(option.studlevel_id)
            );
            setInitialTrainingSelected(selected);
        } else {
            setInitialTrainingSelected([]);
        }
    }, [training, selectedStudLevelIds]);

    useEffect(() => {
        if (area?.length > 0 && selectedAreaIds?.length > 0) {
            const selected = area.filter(option =>
                selectedAreaIds.includes(option.areastud_id)
            );
            setInitialAreaSelected(selected);
        } else {
            setInitialAreaSelected([]);
        }
    }, [area, selectedAreaIds]);

    useEffect(() => {
        if (rate?.length > 0 && selectedAccessRateIds?.length > 0) {
            const selected = rate.filter(option =>
                selectedAccessRateIds.includes(option.access_rate_range_id)
            );
            setInitialAccessSelected(selected);
        } else {
            setInitialAccessSelected([]);
        }
    }, [rate, selectedAccessRateIds]);

    const handleStudLevelChange = (selectedTraining) => {
        //console.log("Selected training:", selectedTraining);
        onSelectStudLevel(selectedTraining);
        if (isAuthenticated) {
            onSelectStudLevel(selectedTraining);
        }
    };

    const handleAreaChange = (selectedArea) => {
        //console.log("Selected area:", selectedArea);
        if (isAuthenticated) {
            onSelectAreaChange(selectedArea);
        }
    };

    const handleAccess = (selectedAccess) => {
        //console.log("Selected access rate:", selectedAccess);
        if (isAuthenticated) {
            onSelectAccessChange(selectedAccess);
        }
    };

    const handleCheckboxChange = (key) => {
        if (isAuthenticated) {
            onCheckboxChange(key);
        }
    };

    return (
        <div  >
            <div className="w-full flex flex-wrap justify-between items-center py-2">
                {/* Mobile Checkboxes */}
                <div className="flex md:hidden mb-2 justify-between  space-x-1 w-full">
                    <CustomCheckbox
                        label="Alternance"
                        isChecked={checkboxValues.alternance}
                        onToggle={() => handleCheckboxChange('alternance')}
                    />

                    <CustomCheckbox
                        label="Distanciel"
                        isChecked={checkboxValues.enDistanciel}
                        onToggle={() => handleCheckboxChange('enDistanciel')}
                    />
                    <CustomCheckbox
                        label="Public"
                        isChecked={checkboxValues.public }
                        onToggle={() => handleCheckboxChange('public')}
                    />
                </div>

                {/* Dropdowns */}
                <div className="flex flex-wrap gap-2 w-full lg:w-auto">
                    {/* Filière */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Filière"
                            options={area}
                            onChange={handleAreaChange}
                            initialSelectedOptions={initialAreaSelected}
                            optionKey="areastud_id"
                            optionLabel="area_studies"
                            width={"155px"}
                            clearFilters={clearFilters}
                        />
                    </div>
                    {/* Type de formation */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Niveau d'études"
                            options={training}
                            onChange={handleStudLevelChange}
                            initialSelectedOptions={initialStudLevelSelected}
                            optionKey="studlevel_id"
                            // optionLabel="type_training"
                            optionLabel="study_level"
                            width={"145px"}
                            clearFilters={clearFilters}
                        />
                    </div>



                    {/* Checkboxes for larger screens */}
                    <div className="hidden md:flex   lg:w-auto justify-center item-center w-full gap-2">
                        <CustomCheckbox
                            label="Alternance"
                            isChecked={checkboxValues.alternance }
                            onToggle={() => handleCheckboxChange('alternance')}
                        />
                        <CustomCheckbox
                            label="Distanciel"
                            isChecked={checkboxValues.enDistanciel}
                            onToggle={() => handleCheckboxChange('enDistanciel')}
                        />
                        <CustomCheckbox
                            label="Public"
                            isChecked={checkboxValues.public }
                            onToggle={() => handleCheckboxChange('public')}
                        />
                    </div>

                    {/* Taux d'accès */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Taux d'accès"
                            options={rate}
                            onChange={handleAccess}
                            initialSelectedOptions={initialAccessSelected}
                            optionKey="access_rate_range_id"
                            optionLabel="access_rate_range"
                            width={"120px"}
                            clearFilters={clearFilters}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}
