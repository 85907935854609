
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, notification, Dropdown, Menu } from 'antd';
import {MenuOutlined, QuestionCircleFilled} from '@ant-design/icons';
import logo from "../images/logo.svg";
import UserIcon from "../images/iconuser.svg";
import LoginModal from '../components/modal/auth/LoginModal';

export default function LandingNav({ setIsSidebarOpen }) {
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nextRoute, setRouteNextPage] = useState("/formations");

    const handleToggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    const showModal = () => {
        setRouteNextPage("/formations");
        setIsModalVisible(true);
    };


    const hideModal = () => setIsModalVisible(false);

    return (
        <nav className="bg-white p-2 flex justify-between items-center fixed top-0 left-0 w-full border-b border-gray-300 z-50">
            <div>
                <Link to="/">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer"
                    />
                </Link>
            </div>

            <div className="hidden md:flex items-center">
                    <>
                        <Link to="/faq">
                            <button className=" py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                                À propos
                            </button>
                        </Link>
                        <Link to="/public/inscription" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            S'inscrire
                        </Link>
                        <Button
                            type="default"
                            className="flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold"
                            onClick={showModal}
                        >
                            <span>S'identifier</span>
                            <img
                                src={UserIcon}
                                alt="Login Icon"
                                className="ml-2 h-5 w-5"
                            />
                        </Button>
                    </>
            </div>

            {/*{location.pathname === '/landing' && (*/}
            {/*    <>*/}
            {/*        <div className="md:hidden flex justify-end">*/}
            {/*            <Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomRight">*/}
            {/*                <Button*/}
            {/*                    type="default"*/}
            {/*                    icon={<MenuOutlined />}*/}
            {/*                />*/}
            {/*            </Dropdown>*/}

            {/*        </div>*/}
            {/*    </>*/}

            {/*)}*/}

                <>
                    <div className="md:hidden flex justify-end">
                        {/*<Dropdown overlay={dropdownMenu1} trigger={['click']} placement="bottomRight">*/}
                        {/*    <Button*/}
                        {/*        type="default"*/}
                        {/*        icon={<MenuOutlined />}*/}
                        {/*    />*/}
                        {/*</Dropdown>*/}
                        <div className="lg:hidden relative mt-1 flex mr-2">
                                <Button
                                    icon={<MenuOutlined />}
                                    className="lg:hidden ml-2"
                                    onClick={handleToggleSidebar}
                                />
                        </div>

                    </div>
                </>

            <LoginModal visible={isModalVisible} onHide={hideModal} toRoute={nextRoute} />
        </nav>
    );
}


