import { useAxios } from './caller.service';
import {useAuth} from "./auth.provider";

export const useFormationService = () => {
    const AxiosInstance = useAxios();
    const { isAuthenticated } = useAuth();

    let getAreaStudies = () => {
        return AxiosInstance.get('/17_area_studies/');
    }
    let getAccessRate = () => {
        return AxiosInstance.get('/23_accessrate_range/');
    }

    let getFaqs = () => {
        return AxiosInstance.get('/get_faqs/');
    }
    let getTrainingType = () => {
        return AxiosInstance.get('/studies_level/');
    }


    const getSearchPostBac = async (userId, page, keyword, apprenticeship, distenciel, pub, trainingType, areaStudies, accessRateRange, location,rank,items) => {
        let query = `/21_postbac/?user_id=${userId}&page_number=${page}`;

        if (keyword) {
            query += `&keyword=${keyword}`;
        }

        if (apprenticeship !== undefined && apprenticeship !== "") {
            query += `&apprenticeship=${apprenticeship}`;
        }

        if (distenciel !== undefined && distenciel !== "") {
            query += `&distenciel=${distenciel}`;
        }

        if (pub !== undefined && pub !== "") {
            query += `&public=${pub}`;
        }


        if (trainingType) {
            query += `&studlevel_id=${trainingType}`;
        }

        if (areaStudies) {
            query += `&area_studies=${areaStudies}`;
        }

        if (accessRateRange) {
            query += `&accessrate_range=${accessRateRange}`;
        }

        if (location) {
            query += `&location=${location}`;
        }
        if (rank) {
            query += `&x=${rank}&`;
        }
        if (items) {
            query += `&items=${items}&`;
        }

        try {
            const response = await AxiosInstance.get(query);
            if (response.data.detail === "No Data Found") {
                return { success: false, message: "No data found.", data: [] };
            } else if (response.data.status === "success" && response.data.data) {
                const { total_count, total_pages, results } = response.data.data;
                return {
                    success: true,
                    message: "Data fetched successfully.",
                    data: {
                        total_count,
                        total_pages,
                        results
                    }
                };
            } else {
                return { success: false, message: "Unexpected response structure.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, message: "Error fetching data.", data: [] };
        }
    };


    const getPublicPostBac = async ( keyword, apprenticeship, distenciel, pub, trainingType, areaStudies, accessRateRange, location,rank,items) => {
        let query = `/getfirst_postbac_public/?page_number=1`;

        if (keyword) {
            query += `&keyword=${keyword}&`;
        }

        if (apprenticeship !== undefined && apprenticeship !== "") {
            query += `&apprenticeship=${apprenticeship}&`;
        }

        if (distenciel !== undefined && distenciel !== "") {
            query += `&distenciel=${distenciel}&`;
        }

        if (pub !== undefined && pub !== "") {
            query += `&public=${pub}&`;
        }

        if (trainingType) {
            query += `&studlevel_id=${trainingType}&`;
        }

        if (areaStudies) {
            query += `&area_studies=${areaStudies}&`;
        }

        if (accessRateRange) {
            query += `&accessrate_range=${accessRateRange}&`;
        }

        if (location) {
            query += `&location=${location}&`;
        }
        if (rank) {
            query += `&x=${rank}&`;
        }
        if (items) {
            query += `&items=${items}&`;
        }

        query = query.endsWith('&') ? query.slice(0, -1) : query;

        try {
            const response = await AxiosInstance.get(query);
            if (response.data.detail === "No Data Found") {
                return { success: false, message: "No data found.", data: [] };
            } else if (response.data.status === "success" && response.data.data) {
                const { total_count, total_pages, results } = response.data.data;
                return {
                    success: true,
                    message: "Data fetched successfully.",
                    data: {
                        total_count,
                        total_pages,
                        results
                    }
                };
            } else {
                return { success: false, message: "Unexpected response structure.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, message: "Error fetching data.", data: [] };
        }
    };



    const getUserIP = async () => {
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error("Failed to fetch IP address:", error);
            return null;
        }
    };


const getFormationById = async (formationId, userId, showModal) => {
    const apiKeydetails = "api_call_details_count";

    const callData = JSON.parse(localStorage.getItem(apiKeydetails)) || {};

    if (!isAuthenticated) {
        const userIP = await getUserIP();
        if (!userIP) {
            return { success: false, message: "Error fetching IP address.", data: null };
        }

        const currentIP = Object.keys(callData)[0];
        if (currentIP !== userIP) {
            delete callData[currentIP];
        }

        const callCount = callData[userIP] || 0;

        // Modification : L'API est appelée même si la limite est atteinte
        if (callCount >= 4) {
            setTimeout(() => {
                showModal(); // Affiche la modalité après 2 secondes
            }, 2000);

            callData[userIP] = callCount + 1; // Incrémentation du compteur
            localStorage.setItem(apiKeydetails, JSON.stringify(callData));
        } else {
            // Si limite non atteinte, on incrémente le compteur comme avant
            callData[userIP] = callCount + 1;
            localStorage.setItem(apiKeydetails, JSON.stringify(callData));
        }
    }

    try {
        const response = await AxiosInstance.get(`/21_postbac_a/?postbac_id=${formationId}&user_id=${userId}`);
        return {
            success: true,
            message: "Data fetched successfully.",
            data: response.data,
        };
    } catch (error) {
        return { success: false, message: "Error fetching data.", data: null };
    }
};




    // let getFormationById = (formationId,userId) => {
    //     return AxiosInstance.get(`/21_postbac_a/?postbac_id=${formationId}&user_id=${userId}`);
    // };

    const savePostBac = async (userId, postbacId) => {
        try {
            const response = await AxiosInstance.post('/save_postbac', {
                user_id: userId,
                postbac_id: postbacId
            });
            if (response.data.status === "success") {
                return { success: true, message: "Postbac saved successfully.", data: response.data };
            } else {
                return { success: false, message: "Failed to save postbac.", data: [] };
            }
        } catch (error) {
            return { success: false, message: "Error saving postbac.", data: [] };
        }
    };

    const deletePostBac = async (userId, postbacId) => {
        try {
            const response = await AxiosInstance.delete(`/delete_postbac/${userId}`, {
                params: { postbac_id: postbacId }
            });
            if (response.data.status === "success") {
                return { success: true, message: "Postbac removed from favorites.", data: response.data };
            } else {
                return { success: false, message: "Failed to remove postbac from favorites.", data: [] };
            }
        } catch (error) {
            console.error("Error deleting postbac:", error);
            return { success: false, message: "Error deleting postbac.", data: [] };
        }
    };

    const getSavedPostbac = async (userId) => {
        try {
            const response = await AxiosInstance.get('/get_saved_postbac', {
                params: { user_id: userId }
            });
            if (response.data.status === "success") {
                return { success: true, data: response.data.data };
            } else {
                return { success: false, message: "Failed to fetch saved postbac.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching saved postbac:", error);
            return { success: false, message: "Error fetching saved postbac.", data: [] };
        }
    };

    return {
        getAreaStudies,
        getAccessRate,
        getTrainingType,
        getSearchPostBac,
        getFormationById,
        savePostBac,
        deletePostBac,
        getSavedPostbac,
        getPublicPostBac,
        getFaqs,
        getUserIP

    }
}
