import { useAxios } from './caller.service';
import { parseUserAgent } from './reference.service';

export const useUserService = () => {
    const AxiosInstance = useAxios();

    let getUserStatus = () => {
        return AxiosInstance.get('/User_Status/');
    }

    let getStudiesLevel = (status = 0, graduate = 0) => {
        let params = {};

        if (status !== 0) {
            params.status = status;
        }

        if (graduate !== 0) {
            params.graduate = graduate;
        }
        // console.log("params ", params)
        return AxiosInstance.get('/studies_level/', { params: params });
    }

    let getBacSpeciality = () => {
        return AxiosInstance.get('/bac_speciality/');
    }

    let getSubjectsCategries = () => {
        return AxiosInstance.get('/subjects_categries/');
    }

    let getBacSpePro = () => {
        return AxiosInstance.get('/bac_spe_pro/');

    }

    let getSalaireRange = () => {
        return AxiosInstance.get('/32_salary_ranges/');

    }
    let getBusinessSector = () => {
        return AxiosInstance.get('/31_business_sector/');

    }

    let getBacSpeTechno = () => {
        return AxiosInstance.get('/bac_spe_techno/');
    }

    let getBacSpeGeneral = () => {
        return AxiosInstance.get('/bac_spe_general/');
    }

    let getBacType = () => {
        return AxiosInstance.get('/bac_type/');
    }

    let get17AreaStudies = () => {
        return AxiosInstance.get('/17_area_studies/');
    }

    let getUserCalculate = (userId) => {
        return AxiosInstance.get(`/user/user_calculate/${userId}`);
    }

    let getUserSubmissionData = (userId) => {
        return AxiosInstance.get(`/GetUserSubmissionData/${userId}`);
    }

    let getUserAiResult = async (user_id) => {
        if (user_id == null) {
            return Promise.reject(new Error("User ID is required"));
        }
        try {
            const reference = parseUserAgent();
            let response = await AxiosInstance.post(`/GetUserResults`, {
                user_id,
                reference
            });
            return response;
        } catch (error) {
            console.error('Error submitting data:', error);
            throw error;
        }
    };

    const saveUserDataSync = async (type,storageKey, typeMF, userId) => {
        if (!type || !userId) {
            // return Promise.reject(new Error("type and userId are required"));
            return ;
        }
    
        // let storageKey;
        // if (type === "saveRecherche") {
        //     storageKey = "formSearchData";
        // } else if (type === "saveClic") {
        //     storageKey = "metSearchData";
        // } else {
        //     return Promise.reject(new Error("Invalid type specified"));
        // }
    
        const data = JSON.parse(localStorage.getItem(storageKey));
    
        if (!data) {
            return Promise.reject(new Error(`No data found for type: ${type}`));
        }
    
        const { checkboxValues, ...restData } = data;
        const payload = {
            data: {
                user_id: userId,
                type: type,
                ...restData,
                ...checkboxValues,
            },
        };
        // if (type === "saveRecherche") {
        //     payload.data.typeMF = "FORMATION";
        // } else if (type === "saveClic") {
        // }
        payload.data.typeMF = typeMF;
        try {
            await AxiosInstance.post(`/user/data-sync`, payload);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };


    const saveUserClickData = async ({
                                         idMetier,
                                         idFormation = null,
                                         formationLabelisee = null,
                                         siteWeb = false,
                                         parcoursup = false,
                                         enregistrer = false,
                                         userId,
                                     }) => {
        if (!userId) return;
        if (!idMetier && !idFormation) {
            return Promise.reject(new Error("Either idMetier or idFormation is required"));
        }


        const payload = {
            data: {
                user_id: userId,
                type: "saveClic",
                idMetier: idMetier || "",
                idFormation: idFormation || "",
                formationLabelisee: formationLabelisee || "",
                siteWeb: siteWeb || "",
                parcoursup: parcoursup || false,
                enregistrer: enregistrer || false,
            },
        };

        try {
            await AxiosInstance.post(`/user/data-sync`, payload);
            console.log("Click data submitted successfully:", payload);
        } catch (error) {
            console.error("Error submitting click data:", error);
        }
    };

    let saveAppointment = async (appointmentData) => {
        try {
            const response = await AxiosInstance.post('/save_appointment', appointmentData);
            return response.data; // Return the response data if needed
        } catch (error) {
            throw error; // Throw error so it can be caught in the calling function
        }
    };

    const checkAppointment = async (userId) => {
        try {
            const response = await AxiosInstance.post('/check_appointment', { user_id: userId });
            return response.data.can_make_appointment;
        } catch (error) {
            throw error; // Propagate the error
        }
    };

    const getCitations = async () => {
        try {
            const response = await AxiosInstance.get('/citations');
            return response.data.data;
        } catch (error) {
            console.error('Error fetching citations:', error);
            throw error;
        }
    };

    return {
        getUserStatus,
        getStudiesLevel,
        getBacSpeciality,
        getSubjectsCategries,
        getBacSpePro,
        getUserSubmissionData,
        getBacSpeTechno,
        getBacSpeGeneral,
        getBacType,
        getUserCalculate,
        getBusinessSector,
        get17AreaStudies,
        getSalaireRange,
        getUserAiResult,
        saveUserDataSync,
        saveUserClickData,
        saveAppointment,
        checkAppointment,
        getCitations

    }
}