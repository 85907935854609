import React, { useState, useEffect, useCallback } from "react";
import "../../style/inputChip.css";
import LocationSelector from "../../utils/multiSelectEmplacement";
import FormationFilters from "../../components/FormationFilters";
import FormationCard from "../../utils/FormationCard";
import { useFormationService } from "../../services/formation.service";
import {Alert, Pagination, Skeleton} from "antd";
import LoginModal from "../../components/modal/auth/LoginModal";
import FormationSearchFooter from "../../components/FormationSearchFooter";
import LayoutNo from "../../utils/LayoutNo";
import {Helmet} from "react-helmet";

export default function StaticSearch({keyword,studyLevel,alternance,areaStudy,title,metaDescription}) {
    const { getPublicPostBac } = useFormationService();
    const [loadingpub, setLoadingPub] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [postBacData, setPostBacData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const page= process.env.REACT_APP_FORMATION_ITEM_PER_PAGE;

    useEffect(() => {
        const fetchData = async () => {
            setLoadingPub(true);
            const response = await getPublicPostBac(keyword,alternance,"","",studyLevel,areaStudy,"","");
            if (response.success) {
                setPostBacData(response.data.results);
                console.log(response.data)
                setTotalCount(response.data.total_count || 0);

                console.log(areaStudy,studyLevel,alternance)
            } else {
            }
            setLoadingPub(false);
        };

        fetchData();
    }, []);




    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);


    return(
        <LayoutNo>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            <div className="p-1 ">

                <div className="  relative z-10 flex justify-center items-center px-3 md:px-5 lg:px-7 md:mt-3 mt-14">
                {/*<div className="-mt-3  relative z-10 flex justify-center items-center px-3 md:px-5 lg:px-7">*/}
                    <div className="bg-white   w-full border-2  rounded-lg md:rounded-md shadow-md flex flex-wrap md:flex-nowrap items-center    space-y-2 md:space-y-0" >

                        {/* Mots clé Input */}
                        <div className="relative flex-grow md:flex-grow-0 md:w-1/2 md:p-0 p-2  border-1 md:border-none border-gray-300 rounded-t-md">
                            <span className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-500 ml-1">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.332 12.333L15.7493 15.7502" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <input
                                type="text"
                                value={keyword}
                                placeholder=" Rechercher une formation, une école, une université.…"
                                className="w-full pl-5  text-sm font-normal   placeholder-[#BEBEBFff]   rounded-l focus:outline-none"
                            />
                        </div>

                        {/* Divider for Large Screens */}
                        <div className="hidden md:block w-px bg-gray-300 h-6  "></div>

                        {/* Divider for Small Screens */}
                        <div className="block md:hidden w-full bg-gray-300  mt-0 mb-1"></div>

                        <div className="flex flex-col md:flex-row flex-grow -mt-1 md:space-y-0 border-1 md:border-none border-gray-300 rounded-b-md" >
                            <LocationSelector onLocationSelect={() => {}} />
                        </div>

                        {/* Recherche Button for Large Screens */}
                        <button
                            type="button"
                            onClick={ showModal }
                            className="hidden md:flex  justify-center items-center p-2 m-1 text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                        >
                            {/* Search Icon */}
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                                <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Recherche
                        </button>

                    </div>
                </div>
                <div className="hidden md:flex   justify-center items-center md:px-5 md:my-2 " >
                    {/*<FormationFilters*/}
                    {/*    checkboxValues={{*/}
                    {/*        alternance: "",*/}
                    {/*        enDistanciel: "",*/}
                    {/*        public: "",*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <FormationFilters
                        checkboxValues={{
                            alternance:alternance === 1 ? "1" : "",
                            enDistanciel: "",
                            public: "",
                        }}
                        selectedAreaIds={[areaStudy]}

                        selectedStudLevelIds={[studyLevel]}
                    />
                </div>
                <div className=" md:hidden  left-0 right-0  px-3 mt-3 ">
                    <button
                        onClick={ showModal }

                        type="button"
                        className="flex  w-full  justify-center items-center py-2  mt-2 text-md font-medium rounded-lg border border-[#1681FF] bg-blue-500 text-white   "
                    >
                        Recherche
                    </button>
                </div>

                {/*</div>*/}
                <div className="md:hidden flex mt-3 px-3" >
                    <FormationFilters
                        checkboxValues={{
                            alternance: alternance === 1 ? "1" : "",
                            enDistanciel: "",
                            public: "",
                        }}
                        selectedAreaIds={[areaStudy]}
                        selectedStudLevelIds={[studyLevel]}
                    />
                </div>
                <div >
                    <div className="flex justify-between mt-3 lg:mt-0 mx-4 md:mx-3 mb-2">
                        <p className="font-poppins font-semibold text-sm mt-2">
                            Formations : {totalCount}

                        </p>
                    </div>
                    <div className=" px-3 md:px-2">

                        {loadingpub  && (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2  p-2">
                                {Array.from({ length: 16 }).map((_, index) => (
                                    <Skeleton active key={index} />
                                ))}
                            </div>
                        )}

                        <LoginModal visible={isModalVisible} onHide={hideModal}  />

                    </div>
                    { !loadingpub  &&(
                        <div className="px-3 md:px-2 ">
                            {/*<Alert message="Inscrivez-vous pour effectuer une recherche (c'est gratuit) !" type="info" className="mx-2 -mt-5 mb-2" showIcon />*/}

                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2  px-2">

                                {Array.isArray(postBacData) && postBacData.map((item) => (
                                    <FormationCard
                                        key={item.postbac_id}
                                        title={item.nom_formation}
                                        smtitle={item.nom_etab}
                                        imgSrc={item.logo}
                                        chips={[
                                            item.departement,
                                            item.niveau,
                                            item.apprentissage ,
                                            item.taux_dacces,
                                            item.amenagement === "En présentiel" ? null : "Distanciel"
                                        ].filter(Boolean)}
                                        data={item}
                                        id={item.postbac_id}
                                    />
                                ))}

                            </div>
                            <div className="flex justify-center mt-4" onClick={showModal}>
                                <Pagination
                                    total={totalCount}
                                    pageSize={page}
                                    showSizeChanger={false}
                                />
                            </div>
                            <FormationSearchFooter/>

                        </div>

                    )}
                </div>
            </div>
        </LayoutNo>
    )
}
