import React from "react";
import LayoutNo from "../../utils/LayoutNo";

export default function MentionsL(){
    return(
        <LayoutNo>
        <div className="p-2 md:mx-24 mx-5 md:mt-1 mt-16">
            <p className="font-bold">Mentions légales de LINKYWAYS</p>
            <div>
                <p>
                    Le site linkyways.com/, ci-après dénommé « le Site », est édité par la société
                    LINKYWAYS, ci-après dénommée « LINKYWAYS ».
                </p>
                <p className="font-bold text-sm font-poppins">LINKYWAYS SASU</p>
                <p>
                    <span className="block">Siège social : 12 Allée Vivaldi, 75012 PARIS</span>
                    <span className="block">Tél. : 06 02 71 42 07</span>
                    <span className="block">E-mail : contact@linkyways.com</span>
                    <span className="block">R.C.S. PARIS B 979 284 106</span>
                    <span className="block">TVA Intra-communautaire : FR41979284106</span>
                    <span className="block">Capital : 1 001€</span>
                    <span className="block">Directeur de la publication : M. Khalid Mansour</span>
                </p>
                <p className="font-bold text-sm font-poppins">Hébergement</p>
                <p>
                    Le site est hébergé par Heroku, une plateforme de cloud computing exploitée
                    par Salesforce. Les serveurs utilisés pour le Site sont situés au sein de l’Union
                    européenne afin de garantir la conformité avec le Règlement Général sur la
                    Protection des Données (RGPD).
                </p>

                <span className="block font-bold">Heroku, Inc.</span>
                <span className="block">Adresse : 650 7th St, San Francisco, CA 94103, États-Unis</span>
                <span className="block">Serveurs localisés en Europe</span>
                <span className="block">Site internet :https://www.heroku.com/</span>

                <hr className="border-2 border-gray-600 my-2 "/>
                <p className="font-bold text-sm font-poppins">Confidentialité et protection des données</p>
                <p>
                    Les informations fournies par les Utilisateurs, y compris leurs coordonnées, sont
                    utilisées dans le cadre de la mise en relation avec des établissements
                    d’enseignement supérieur.
                    Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 et au
                    Règlement Général sur la Protection des Données (RGPD), vous pouvez à tout
                    moment modifier ou supprimer les renseignements liés à votre inscription.
                    LINKYWAYS s'engage à protéger la confidentialité de ces informations.
                    Vous disposez des droits suivants concernant vos données personnelles :
                </p>

                <ul class="list-disc ml-5">
                    <li>Droit d’accès</li>
                    <li>Droit de rectification</li>
                    <li>Droit d’effacement</li>
                    <li>Droit d’opposition</li>
                    <li>Droit à la limitation du traitement</li>
                    <li>Droit à la portabilité des données</li>
                    <li>Droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage)</li>
                </ul>

                <p>Vous pouvez exercer ces droits en nous contactant par :</p>
                <p className="font-bold text-sm font-poppins">Confidentialité et protection des données</p>

                <ul className="list-disc ml-5">
                    <li className="font-bold"> Voie postale :</li>
                    <span className="block">LINKYWAYS SASU</span>
                    <span className="block">   12, Allée Vivaldi, 75012 PARIS</span>
                    <span className="block">Tél. : 06 02 71 42 07</span>
                    <li className="font-bold mt-2"> Voie électronique :</li>
                    <span className="block">E-mail : contact@linkyways.com</span>
                </ul>
                <p>Pour plus d'informations, vous pouvez consulter le site de la CNIL :https://www.cnil.fr/ </p>
                <hr className="border-2 border-gray-600 my-2 "/>
                <p className="font-bold text-sm font-poppins">Cookies</p>
                <p className="font-bold text-sm font-poppins">Qu'est-ce qu'un cookie ?</p>
                <p>
                    Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre
                    navigateur lorsque vous accédez à un site internet. Ils permettent de suivre la
                    navigation des utilisateurs et de faciliter l'usage du Site.
                </p>
                <p className="font-bold text-sm font-poppins">Dans quel but sont utilisés les cookies sur ce site ?</p>
                <p>Les cookies sur ce Site sont utilisés pour :</p>
                <ul className="list-disc ml-5">
                    <li className="font-bold">Améliorer l'expérience de navigation.</li>
                    <span className="block">Analyser le comportement des utilisateurs pour mieux adapter les servicesproposés</span>
                    <span className="block">Mémoriser les préférences de l'Utilisateur.</span>
                </ul>
                <p>Aucune donnée personnelle telle que vos coordonnées n’est stockée dans les cookies utilisés sur le Site</p>

                <p className="font-bold text-sm font-poppins">Les différents types de cookies utilisés :</p>
                <ul className="list-disc ml-5 ">
                    <li >
                        <span className="block mt-1"><span className="font-bold">Cookies nécessaires :</span> Ils permettent de sécuriser votre connexion etmémoriser vos préférences.</span>
                    </li>
                    <li >
                        <span className="block mt-1"><span className="font-bold">Cookies de performance :</span> : Ils collectent des informations anonymes sur la manière dont le site est utilisé afin d’améliorer son fonctionnement</span>
                    </li>
                    <li >
                        <span className="block mt-1"><span className="font-bold">Cookies publicitaires :</span> :Ils collectent des informations pour proposer des publicités adaptées à vos centres d’intérêts</span>
                    </li>

                </ul>
                <p className="font-bold text-sm font-poppins">Droit d'accès et opposition</p>
                <p>
                    Conformément à la réglementation en vigueur, vous pouvez vous opposer au
                    dépôt de cookies via les paramètres de votre navigateur. Vous pouvez également
                    configurer votre navigateur pour être informé de la présence de cookies et
                    décider de les accepter ou non.
                </p>
                <hr className="border-2 border-gray-600 my-2 "/>
                <p className="font-bold text-sm font-poppins">Droits d’auteur et Copyright</p>
                <p>
                    Le contenu du Site (textes, graphiques, logos, marques, etc.) est protégé par les
                    lois en vigueur sur la propriété intellectuelle. Ces éléments appartiennent à
                    LINKYWAYS ou à ses partenaires. Aucune partie du Site ne peut être copiée,
                    reproduite ou modifiée sans autorisation préalable, sauf pour un usage strictement personnel.

                </p>
                <p>
                    Toute utilisation non autorisée du Site ou de ses contenus constituerait une
                    violation des droits de LINKYWAYS, passible de poursuites conformément aux
                    articles L355-2 et suivants du code de la propriété intellectuelle.
                    Contenu utilisateur publié sur le Site
                    En publiant un contenu sur le Site, l’utilisateur cède automatiquement à
                    LINKYWAYS tous les droits sur ce contenu sans restriction.

                </p>
                <hr className="border-2 border-gray-600 my-2 "/>
                <p className="font-bold text-sm font-poppins"> Responsabilité</p>
                <p>
                    Les informations présentes sur le Site sont fournies en l’état. LINKYWAYS ne
                    garantit ni l’exactitude ni l'exhaustivité des informations et ne pourra être tenue
                    responsable des dommages directs ou indirects liés à leur utilisation. L’Utilisateur
                    est seul responsable de l’usage qu’il fait des informations fournies.
                    LINKYWAYS se réserve le droit de modifier à tout moment les présentes mentions
                    légales et les contenus du Site.
                    L'Utilisateur s'engage à ne pas diffuser via le Site des informations illégales ou
                    contraires à l'ordre public.

                </p>
                <hr className="border-2 border-gray-600 my-2 "/>
                <p className="font-bold text-sm font-poppins"> Liens hypertextes</p>
                <p>
                    La création de liens hypertextes vers le Site est soumise à l’autorisation préalable
                    de LINKYWAYS.
                </p>
                <hr className="border-2 border-gray-600 my-2 "/>

                <p className="font-bold text-sm font-poppins">   Messagerie</p>
                <p>
                    Pour toute question relative au fonctionnement du Site, vous pouvez nous
                    contacter à l’adresse suivante : contact@linkyways.com
                    Important
                    Les messages envoyés via internet peuvent être interceptés. Jusqu'à leur réception, leur confidentialité ne peut être garantie.
                </p>

            </div>
        </div>
        </LayoutNo>
    )
}