import React from "react";

const ContactAvatar = () => {
    return(
        <svg width="186" height="179" viewBox="0 0 186 179" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3677_20551)">
                <path d="M111.12 29.3697C111.12 30.8072 111.545 32.2124 112.342 33.4076C113.139 34.6028 114.271 35.5344 115.596 36.0845C116.921 36.6346 118.379 36.7785 119.785 36.4981C121.192 36.2177 122.483 35.5254 123.497 34.509C124.511 33.4925 125.202 32.1975 125.482 30.7876C125.762 29.3777 125.618 27.9163 125.069 26.5883C124.52 25.2602 123.591 24.1251 122.399 23.3265C121.206 22.5278 119.805 22.1016 118.371 22.1016C116.448 22.1016 114.603 22.8673 113.244 24.2303C111.884 25.5934 111.12 27.442 111.12 29.3697Z" fill="#807066"/>
                <path d="M142.895 29.3697C142.895 30.8072 143.321 32.2124 144.117 33.4076C144.914 34.6028 146.046 35.5344 147.371 36.0845C148.696 36.6346 150.154 36.7785 151.56 36.4981C152.967 36.2177 154.259 35.5254 155.273 34.509C156.287 33.4925 156.977 32.1975 157.257 30.7876C157.537 29.3777 157.393 27.9163 156.845 26.5883C156.296 25.2602 155.366 24.1251 154.174 23.3265C152.982 22.5278 151.58 22.1016 150.146 22.1016C148.223 22.1016 146.379 22.8673 145.019 24.2303C143.659 25.5934 142.895 27.442 142.895 29.3697Z" fill="#807066"/>
                <path d="M153.687 30.4298C153.806 29.8081 156.543 15.1215 149.493 6.31731C146.274 2.29822 141.557 0.174888 135.476 0.0123364C128.676 -0.170534 123.383 1.68052 119.745 5.51065C113.07 12.541 113.956 24.1574 114.288 28.53C114.325 29.0136 114.355 29.4037 114.365 29.6801L117.077 29.5825C117.067 29.2798 117.035 28.8531 116.994 28.3228C116.686 24.2793 115.869 13.5407 121.711 7.39422C124.796 4.14318 129.401 2.58065 135.403 2.73914C140.647 2.88138 144.675 4.65725 147.363 8.02208C153.628 15.8368 151.042 29.7817 151.011 29.9219L153.687 30.4298Z" fill="#807066"/>
                <path d="M20.5942 107.374C20.5942 107.374 6.07891 104.312 4.98635 104.935C3.8938 105.559 3.83299 106.39 5.18905 106.734C6.54512 107.077 19.151 110.129 19.151 110.129C19.151 110.129 1.5506 109.487 0.543179 109.794C-0.0487061 109.974 -0.267626 111.553 0.437771 111.807C1.14317 112.061 18.6139 113.738 18.6139 113.738C18.6139 113.738 2.18099 113.169 1.42492 113.693C0.764116 114.152 0.912085 115.676 2.13234 115.806C3.35259 115.936 18.624 117.362 18.624 117.362C18.624 117.362 6.68295 117.732 6.31606 118.582C6.09107 119.104 5.83769 120.455 8.91062 120.258C11.9835 120.061 19.3233 120.396 19.3233 120.396L20.5942 107.374Z" fill="#EEA886"/>
                <path d="M96.5339 126.677C97.4339 130.594 97.2798 139.681 97.2798 139.681C97.523 148.31 96.5643 157.155 96.321 162.111C96.094 166.496 95.6602 173.616 95.3258 178.992H161.244C160.838 167.865 160.013 144.08 160.163 136.499C160.348 126.845 165.75 106.75 166.147 105.303C166.147 105.289 166.147 105.281 166.147 105.271C166.146 105.267 166.145 105.263 166.145 105.26C166.145 105.256 166.146 105.252 166.147 105.248C166.148 105.238 166.148 105.228 166.147 105.218C166.147 105.218 170.163 124.586 170.27 131.917C170.379 138.927 169.329 166.604 170.497 179H184.305C184.812 167.034 186.202 132.856 185.748 127.187C185.523 124.366 181.777 93.8982 178.544 77.1797C177.395 71.2303 176.304 67.0202 175.463 66.4107C173.278 64.838 159.559 59.3275 152.445 56.6169C149.153 55.3612 146.645 54.4855 146.13 54.4529C144.509 54.3534 124.442 56.2817 124.442 56.2817C124.442 56.2817 121.563 57.2692 118.62 58.6204C113.599 60.9327 105.773 64.5433 100.16 67.2214C98.3359 68.089 96.0758 69.2533 94.1886 70.255C94.2616 72.5043 94.3609 74.8126 94.4744 77.1635C94.7055 81.9709 95.0143 87.0006 95.4008 92.2523C95.4008 92.2523 96.4386 109.393 96.5339 126.677Z" fill="#CAC7D1"/>
                <path d="M95.3886 153.482C95.3886 153.47 95.3886 153.46 95.3886 153.441C95.5088 152.45 95.6251 151.464 95.7372 150.485C95.7372 150.44 95.7372 150.385 95.7514 150.331C96.2257 146.21 96.6149 142.266 96.8967 138.647C97.0756 135.933 96.9553 133.207 96.5379 130.52C95.417 112.029 94.8595 92.5332 94.8595 92.5332C94.4663 87.1771 94.1825 82.0628 93.9778 77.1802C93.8697 74.7825 93.7805 72.437 93.7102 70.1437C95.0095 69.142 97.8818 67.6038 99.8987 66.6428C99.8987 66.6428 117.834 58.4948 121.182 57.5195C121.182 57.5195 118.547 63.7148 115.732 77.1802C114.75 81.8535 113.741 87.427 112.819 93.9169C111.442 103.575 108.892 119.834 107.038 136.428C105.572 149.51 102.234 162.934 101.176 172.946C101.003 174.547 100.782 176.623 100.529 178.994H91.6913C93.0515 170.883 94.367 161.925 95.3886 153.482Z" fill="#1A8235"/>
                <path d="M122.702 63.3956C122.702 63.3956 122.165 71.0945 128.664 71.2733C135.162 71.4521 140.319 65.2 140.319 65.2L129.533 67.9877L122.97 62.4102L122.702 63.3956Z" fill="#CAC7D1"/>
                <path d="M36.8911 113.583C36.8911 113.583 32.1581 112.263 31.5256 109.426C30.9885 107.014 28.6433 104.204 27.9237 103.331C26.9588 102.17 25.2116 97.5376 23.8697 98.71C22.5278 99.8824 26.4054 106.472 26.9649 108.008C27.5244 109.544 20.969 106.848 19.4528 107.317C17.9367 107.786 14.8779 119.131 19.5522 120.451C24.2264 121.772 29.8899 120.006 30.6682 120.407C31.4466 120.807 34.0412 121.504 34.0412 121.504L36.8911 113.583Z" fill="#EEA886"/>
                <path d="M131.473 4.41063C131.473 4.41063 116.571 6.93018 119.109 30.3173C121.646 53.7045 134.781 51.64 135.661 51.6096C136.541 51.5791 148.891 51.89 149.542 23.2971C149.542 23.2951 149.732 2.15522 131.473 4.41063Z" fill="#EEA987"/>
                <path d="M124.944 44.8275C124.944 44.8275 124.944 54.4628 124.551 56.5475C124.127 58.7826 136.51 65.2704 144.515 57.7199C144.515 57.7199 142.893 47.2008 143.4 43.4844C143.907 39.7681 124.944 44.8275 124.944 44.8275Z" fill="#EEA987"/>
                <path d="M117.843 21.7184C118.358 24.9206 118.843 27.2959 118.843 27.2959L121.012 30.1406C121.012 30.1406 120.882 23.862 121.214 22.8765C121.34 22.5027 121.486 21.8403 121.64 21.1251C121.887 19.9405 122.143 18.6055 122.317 18.1605C122.597 17.4494 135.087 13.7919 146.215 18.9265C146.215 18.9265 146.122 19.8937 146.128 20.9706C146.128 22.2243 146.268 23.6284 146.842 23.8397C147.91 24.2298 147.464 31.187 147.464 31.187L149.199 29.1795C149.199 29.1795 149.7 25.5465 150.192 21.3709C150.193 21.3608 150.193 21.3506 150.192 21.3404C150.304 20.3651 150.417 19.3634 150.527 18.3698C150.776 14.1699 150.36 6.54213 144.904 2.05976C141.223 0.480977 135.342 -0.862107 125.542 2.1451C125.542 2.1451 123.097 2.84407 122.467 3.45161C116.37 10.1813 117.843 21.7184 117.843 21.7184Z" fill="#434343"/>
                <path d="M142.378 48.4805C142.378 48.4805 139.999 51.7904 134.473 51.6665C128.948 51.5425 126.55 48.6532 126.55 48.6532C126.55 48.6532 129.164 54.1393 134.499 54.0783C139.835 54.0174 142.378 48.4805 142.378 48.4805Z" fill="#C78D71"/>
                <path d="M123.973 63.8595L129.533 67.9822C129.533 67.9822 124.871 58.0564 124.768 54.082L123.973 63.8595Z" fill="#A09EA6"/>
                <path d="M124.768 54.082L120.985 57.6074L117.006 69.5001L123.973 63.8595L124.768 54.082Z" fill="#EAE6F2"/>
                <path d="M30.6319 124.591C30.6319 124.591 63.4449 133.251 71.4658 131.274C75.1752 130.36 80.0582 125.227 84.7 119.196C86.2284 117.209 87.7304 115.133 89.1594 113.06C94.8553 104.811 99.3187 96.9208 99.3187 96.9208C99.3187 96.9208 116.143 77.1484 102.643 68.334C102.643 68.334 96.9512 67.759 93.7242 70.1526C90.7344 72.3633 83.4615 86.2818 82.2798 88.8643C81.0981 91.4468 68.3097 115.403 67.4523 115.941C66.5949 116.48 55.6309 114.519 49.8377 114.031C43.9797 113.516 38.1839 112.443 32.5292 110.825L30.6319 124.591Z" fill="#1A8235"/>
                <path d="M125.483 57.627C125.483 57.627 127.02 63.0379 129.537 67.9896C129.537 67.9896 135.373 62.8652 137.209 61.0385C139.046 59.2118 125.483 57.627 125.483 57.627Z" fill="#EEA987"/>
                <path d="M135.638 62.2539L129.533 67.9818C129.533 67.9818 136.751 67.5023 138.099 66.2953L135.638 62.2539Z" fill="#A09EA6"/>
                <path d="M137.201 142.66C138.517 155.437 139.974 165.407 141.053 176.089C141.151 177.068 141.248 178.039 141.342 179.001H168.081C164.531 166.567 161.391 152.232 161.391 143.694C161.391 143.694 163.725 114.553 165.571 108.136C165.571 108.136 168.867 121.652 169.66 132.316C170.112 138.466 168.338 164.962 168.743 179.011H186.986C187.418 165.234 188.3 134.335 187.896 130.272C187.613 127.4 184.124 94.4391 181.049 77.1822C180.036 71.438 179.051 67.429 178.25 66.8581C176.399 65.5252 162.689 60.6547 155.953 57.999C154.723 57.5134 150.833 55.8716 149.73 55.4551C148.952 56.3207 144.908 65.4114 141.194 77.1822C138.091 87.0125 135.229 98.7203 134.69 109.083C134.343 116.022 135.967 130.686 137.201 142.66Z" fill="#1A8235"/>
                <path d="M143.736 51.6973C143.736 51.6973 138.853 59.6562 135.638 62.253L141.057 71.2665L149.74 55.4522L143.736 51.6973Z" fill="#EAE6F2"/>
                <path d="M126.839 45.4067L127.6 44.1876C117.765 37.9781 113.836 27.8125 113.798 27.7109L112.46 28.2169C112.624 28.6497 116.583 38.9331 126.839 45.4067Z" fill="#807066"/>
                <path d="M125.785 44.7995C125.787 45.2995 125.936 45.788 126.214 46.2032C126.492 46.6185 126.886 46.9417 127.347 47.1323C127.809 47.3228 128.316 47.372 128.805 47.2737C129.294 47.1754 129.743 46.934 130.096 46.58C130.448 46.226 130.688 45.7752 130.784 45.2847C130.881 44.7941 130.831 44.2858 130.64 43.8239C130.449 43.362 130.125 42.9673 129.71 42.6896C129.295 42.4119 128.808 42.2637 128.309 42.2637C127.977 42.2634 127.648 42.3289 127.341 42.4563C127.035 42.5837 126.756 42.7706 126.522 43.0062C126.287 43.2418 126.101 43.5215 125.975 43.8292C125.849 44.137 125.784 44.4667 125.785 44.7995Z" fill="#807066"/>
            </g>
            <defs>
                <clipPath id="clip0_3677_20551">
                    <rect width="188" height="179" fill="white" transform="matrix(-1 0 0 1 188 0)"/>
                </clipPath>
            </defs>
        </svg>


    )
}
export default ContactAvatar;