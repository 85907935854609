import React, { useState } from 'react';
import HeartIcon from '../images/heart.svg';
import FilledHeartIcon from '../images/heart-filled.svg';
import {useNavigate} from "react-router-dom";
import { CryptoService } from '../services/crypte.service';
import {useAuth} from "../services/auth.provider";
import LoginModal from "../components/modal/auth/LoginModal";
import {useUserService} from "../services/userforms.service";

const MetierCard = ({
    imgSrc,
    smtitle,
    title,
    chips,
    frompriceDebutant,
    topriceDebutant,
    frompriceExp,
    topriceExp,
    professionId,
    saveProfession,
    deleteProfession,
    saved,
    userId

}) => {
    const [isSaved, setIsSaved] = useState(saved !==0);
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { saveUserDataSync,saveUserClickData } = useUserService();



    const handleHeartClick = async () => {
        if (!isAuthenticated) {
            showModal();
            return;
        }

        if (isSaved) {
            try {
                await deleteProfession(professionId);
                saveUserClickData({
                    idMetier: professionId,
                    userId,
                    enregistrer: false,
                });
                setIsSaved(false);
            } catch (error) {
                console.error('Error deleting profession:', error);
            }
        } else {
            try {
                await saveProfession(professionId);
                saveUserClickData({
                    idMetier: professionId,
                    userId,
                    enregistrer: true,
                });
                setIsSaved(true);
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const goToDetailPage = () => {
        const professionName = CryptoService.nameUrlFormat(title.toString())
        saveUserDataSync("saveRecherche","formMetiersSearchData","METIER", userId);
        saveUserClickData({
            idMetier: professionId,
            userId,
            enregistrer: isSaved,
        });
        navigate(`/metiers/${professionName}/${professionId}`);
    };
    // const goToDetailPage = async () => {
    //
    //
    //         const professionName = CryptoService.nameUrlFormat(title.toString());
    //         saveUserDataSync("saveRecherche", "formMetiersSearchData", "METIER", userId);
    //         navigate(`/metiers/${professionName}/${professionId}`);
    //     } catch (error) {
    //         console.error("Error while saving click data:", error);
    //     }
    // };

    return (
        <>
        <div className="bg-white border-1 border-[#D0DBE7]  rounded-md shadow-lg p-2 relative cursor-pointer" >
            <div className="flex justify-between items-start">
                <div className="flex items-center space-x-2">
                    <img src={imgSrc} alt="logo" className="max-w-12 p-1" />
                    <div>
                        <p className="font-inter text-[14px] font-medium leading-[17.5px] text-[#434769] text-left  max-h-[20px]">
                            {smtitle || ''}
                        </p>
                    </div>
                </div>
                <div className="flex space-x-1 justify-end">

                    <button onClick={handleHeartClick}>
                        <img
                            src={!isAuthenticated || !isSaved ? HeartIcon : FilledHeartIcon}
                            // src={isSaved ? FilledHeartIcon : HeartIcon}
                            alt={isSaved ? "remove from favorites" : "add to favorites"}
                            className="object-cover min-w-6"
                        />
                    </button>
                </div>
            </div>
            <div onClick={goToDetailPage}>
                <h3 className="font-inter font-bold text-[#1A3866] mt-4 min-h-[35px] cursor-pointer" >{title || ''}</h3>
                <hr className="my-2" />
                <div className="flex flex-wrap gap-2">
                    {chips.map((chip, idx) => (
                        <span key={idx} className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded">
                        {chip}
                    </span>
                    ))}
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="text-xs text-[#1A3866] font-medium">Débutant :</p>
                    <p className="text-xs font-medium text-gray-600">{frompriceDebutant}€ - {topriceDebutant}€</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="text-xs text-[#1A3866] font-medium">Expérimenté :</p>
                    <p className="text-xs font-medium text-gray-600">{frompriceExp}€ - {topriceExp}€</p>
                </div>
            </div>

        </div>
            <LoginModal visible={isModalVisible} onHide={hideModal}   />
        </>
    );
};

export default MetierCard;
