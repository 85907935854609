import React, { useState, useEffect } from "react";
import editIcon from "../images/editicon.svg";
import RatingComponent from "../utils/Rating";
import {Link, useNavigate} from "react-router-dom";

export default function Categories({ categoriesList }) {
    const [hoveredSubjectId, setHoveredSubjectId] = useState(null);
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/public/test-orientation');
    };

    const handleMouseEnter = (subjectId) => {
        setHoveredSubjectId(subjectId);
    };

    const handleMouseLeave = () => {
        setHoveredSubjectId(null);
    };

    return (
        <>
            <div className="flex justify-between  px-3 ">
                <div className="justify-start py-1">
                    <p className="font-poppins font-bold text-md  text-[#141943] ">
                        Sujets sélectionnés
                    </p>
                </div>
                {/*<div className=" md:hidden flex justify-end pr-2 -mt-1">*/}
                {/*    <Link to={'/public/moteur'} >*/}
                {/*        <div className="relative inline-block">*/}
                {/*            <button className="p-1 border-2 rounded-md  shadow-sm bg-white hover:bg-gray-100 border-blue-500">*/}
                {/*                <img src={editIcon} alt="edit1" />*/}
                {/*            </button>*/}
                {/*            <span className="absolute -top-1 -right-1 p-1 bg-sky-400 rounded-full animate-ping animation-duration-2000"></span>*/}
                {/*        </div>*/}

                {/*    </Link>*/}
                {/*</div>*/}
            </div>
            <div className="px-1 -mt-3 px-3">
                {categoriesList?.length > 0 ? (
                    categoriesList?.map((item) => (
                        <div
                            key={item.sub_name}
                            className="flex items-center mt-2 justify-between relative"
                            onMouseEnter={() => handleMouseEnter(item.sub_name)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                className={`cursor-pointer font-inter text-xs whitespace-nowrap pt-1 text-[#141943] overflow-hidden sm:truncate md:max-w-[20ch]`}
                            >
                                <span className="block sm:hidden">
                                    {item.sub_name}
                                </span>
                                <span className="hidden  sm:block">
                                    {item.sub_name && item.sub_name.length > 20 ? item.sub_name.slice(0, 20) + "..." : item.sub_name || 'N/A'}
                                    {/*{item.sub_name}*/}
                                </span>
                            </div>
                            {hoveredSubjectId === item.sub_name && (
                                <div className="absolute top-full left-0 mt-1 bg-gray-700 text-white text-xs rounded py-1 px-2 z-10 shadow-lg whitespace-nowrap">
                                    {item.sub_name}
                                </div>
                            )}
                            <RatingComponent
                                ratingValue={item.subject_level}
                                //onRatingChange={(newRating) => setRating(newRating)}
                                maxRating={5}
                                starColor="text-yellow-400"
                                inactiveStarColor="text-gray-300"
                                size="min-w-6 p-1 "
                            />
                        </div>

                    ))
                ) : (
                    <div className="animate-pulse mt-3 px-1">
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  "></div>
                    </div>
                )}
                <div className="md:flex flex-col hidden mt-4 gap-2">
                    <Link to={'/public/test-orientation/sujets'} >
                    <button
                        //onClick={handleNavigate}
                        className="w-full bg-blue-500 p-1 border-1 rounded-full text-white font-bold text-sm cursor-pointer">
                        Modifier les sujets
                    </button>
                    </Link>
                    <button
                        onClick={handleNavigate}
                        className="w-full bg-white p-1 border-1 rounded-full text-blue-500 font-bold text-sm cursor-pointer border-1 border-blue-500">
                        Repasser le test
                    </button>
                </div>

            </div>
        </>
    );
}
