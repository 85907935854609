import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ArrowUp from "../images/arrow-up.svg";
import ArrowDown from "../images/arrow-down.svg";
import CustomRadio from "../utils/CustomRadio";
import { useQuestionsService } from "../services/questions.service";
import { useProgress } from '../context/ProgressContext';
import LayoutNo from "../utils/LayoutNo";
import {Helmet} from "react-helmet";

const options = [
    { value: "D'accord", score: 5 },
    { value: "Plutôt d'accord", score: 4 },
    { value: "Neutre", score: 3 },
    { value: "Plutôt pas d'accord", score: 2 },
    { value: "Pas d'accord", score: 1 }
];

const pageProgress = {
    "Environnement de travail": { start: 5, end: 52 },
    "Personnalité": { start: 52, end: 90 }
};

export default function Testing() {
    const navigate = useNavigate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState("");
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [responses, setResponses] = useState([]);
    const [autoAdvance, setAutoAdvance] = useState(false);
    const { setCurrentPage, setFinalSectionProgress } = useProgress();
    const { getQuestions } = useQuestionsService();

    useEffect(() => {
        fetchData();
        
        handleResize();
    
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
            document.body.classList.remove("overflow-y-hidden");
        };
    }, []);

    const handleResize = () => {
        // console.log("H ", window.innerHeight);
        // console.log("W ", window.innerWidth);
        
        if (window.innerHeight < 700) {
            // Si la hauteur de l'écran est inférieure à 700px, autoriser le défilement
            document.body.classList.remove("overflow-y-hidden");
        } else {
            // Si la hauteur de l'écran est supérieure ou égale à 700px, désactiver le défilement
            document.body.classList.add("overflow-y-hidden");
        }
    };

    const fetchData = async () => {
        try {
            const response = await getQuestions();
            if (response.data.status === "success") {
                const fetchedData = response.data.data.map((item) => ({
                    question_id: item.question_id,
                    question_text: item.question_text,
                    question_page: item.question_page,
                }));
                setQuestions(fetchedData);
            } else {
                setError(response.data.message || "An error occurred while fetching questions.");
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching questions.");
        } finally {
            setLoading(false);
        }
    };

    const updateResponses = useCallback(() => {
        if (selectedOption === "") return responses;
    
        const selectedOptionObj = options.find(option => option.score === parseInt(selectedOption));
        if (!selectedOptionObj) {
            console.error("Option not found for the score:", selectedOption);
            return responses;
        }
        // console.log("currentQuestionIndex ", currentQuestionIndex);
    
        const updatedResponses = [...responses];
        updatedResponses[currentQuestionIndex] = {
            questionId: questions[currentQuestionIndex]?.question_id,
            score: selectedOptionObj.score,
        };
        // console.log("updatedResponses[currentQuestionIndex] ", updatedResponses[currentQuestionIndex]);
    
        setResponses(updatedResponses);
        saveToLocalStorage(updatedResponses);
        return updatedResponses;
    }, [selectedOption, responses, currentQuestionIndex, questions]);
    

    const saveToLocalStorage = useCallback((updatedResponses = responses) => {
        const questionsScores = updatedResponses.reduce((acc, response) => {
            acc[response.questionId] = response.score;
            return acc;
        }, {});
    
        localStorage.setItem('questionsScores', JSON.stringify(questionsScores));
    }, []);
    

    const handleArrowClick = useCallback((direction) => {
        if (direction === "down" && selectedOption === "") {
            alert("Vous devez sélectionner une option avant de continuer.");
            return;
        }
        if (isLastQuestion && selectedOption !== "") {
            const updatedResponses = updateResponses();
            saveToLocalStorage(updatedResponses);
            navigate("/public/test-orientation/sujets");
        }
        
        const updatedResponses = updateResponses();

        if (direction === "down" && currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(prev => prev + 1);
            setSelectedOption("");
            setAutoAdvance(false);

            if (calculateOverallProgress(updatedResponses) === 88) {
                saveToLocalStorage();
                navigate("/public/test-orientation/sujets");
            }
        } else if (direction === "up" && currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prev => prev - 1);
            setSelectedOption(updatedResponses[currentQuestionIndex - 1]?.score || "");
        }
    }, [currentQuestionIndex, questions, selectedOption, navigate, updateResponses]);

    useEffect(() => {
        if (autoAdvance) {
            const timer = setTimeout(() => {
                handleArrowClick("down");
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [autoAdvance, handleArrowClick]);

    const handleChange = useCallback((event) => {
        setSelectedOption(event.target.id);
        setAutoAdvance(true);
    }, []);

    const calculateSectionProgress = useCallback((questionPage) => {
        const { start, end } = pageProgress[questionPage] || { start: 0, end: 100 };
        const sectionQuestions = questions.filter(q => q.question_page === questionPage);

        const totalQuestionsInSection = sectionQuestions.length;

        if (totalQuestionsInSection === 0) return start;

        const answeredQuestions = responses.filter(response => {
            const question = questions.find(q => q.question_id === response.questionId);
            return question && question.question_page === questionPage;
        }).length;

        return start + (answeredQuestions / totalQuestionsInSection) * (end - start);
    }, [questions, responses]);

    const calculateOverallProgress = useCallback((responses) => {
        const totalSections = Object.keys(pageProgress).length;
        let totalProgress = 0;

        for (const page in pageProgress) {
            totalProgress += calculateSectionProgress(page);
        }

        return totalProgress / totalSections;
    }, [calculateSectionProgress]);

    const sectionProgress = useMemo(() => {
        const uniquePages = [...new Set(questions.map(q => q.question_page))];
        return uniquePages.reduce((acc, page) => {
            acc[page] = calculateSectionProgress(page);
            return acc;
        }, {});
    }, [questions, calculateSectionProgress]);

    const currentQuestion = questions[currentQuestionIndex];
    const currentPage = currentQuestion?.question_page;
    const finalSectionProgress = sectionProgress[currentPage] || 0;

    useEffect(() => {
        setCurrentPage(currentPage);
        setFinalSectionProgress(finalSectionProgress);
    }, [currentPage, finalSectionProgress, setCurrentPage, setFinalSectionProgress]);

    const isLastQuestion = currentQuestionIndex === questions.length - 1;
    const isFirstQuestion = currentQuestionIndex === 0;

    return (
        <LayoutNo>
            <Helmet>
                <title>Linkyways</title>
            </Helmet>
        <div className="flex flex-col p-1 lg:-ml-64 mt-6 items-center bg-white">
            <div className="relative max-w-lg w-full p-4">
                <img
                    src={ArrowUp}
                    alt="ArrowUp"
                    className={`mb-4 md:mt-2 max-sm:mt-6 ${isFirstQuestion ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={!isFirstQuestion ? () => handleArrowClick("up") : undefined}
                />
                <div className="w-full">
                    <p className="font-poppins text-left mb-4 text-[24px] text-[#141943]"> 
                        {loading ? "Chargement..." : currentQuestion?.question_text || "Question non trouvée"}
                    </p>
                    <div className="flex flex-col w-full space-y-2">
                        {options.map((item) => (
                            <CustomRadio
                                id={item.score.toString()}
                                key={item.score}
                                value={item.value}
                                label={item.value}
                                checked={parseInt(selectedOption) === item.score}
                                onChange={handleChange}
                            />
                        ))}
                    </div>
                </div>
                <img
                    src={ArrowDown}
                    alt="ArrowDown"
                    className={`mt-4 ${isLastQuestion ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                    onClick={() => handleArrowClick("down")}
                />
            </div>
        </div>
        </LayoutNo>
    );
}
