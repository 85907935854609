import LayoutNo from "../utils/LayoutNo";
import Igikai from "../images/igikai.svg";
import RedHeart from "../images/ikigai/redhearticon.svg";
import Money from "../images/ikigai/Money.svg";
import Charity from "../images/ikigai/charity.svg";
import Tool from "../images/ikigai/tool.svg";
import Handicon from "../images/landingpage/handicon";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useUserService } from "../services/userforms.service";
import { CryptoService } from "../services/crypte.service";
import { Button } from 'antd';
import {Helmet} from "react-helmet";

export default function QuiCeQueIkegai() {
    const [userCalculate, setUserCalculate] = useState();
    const [viewResult, setViewResult] = useState(false);
    const navigate = useNavigate();
    const { getUserCalculate } = useUserService();

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const decryptedId = CryptoService.decryptData(userId)
            const response = await getUserCalculate(decryptedId);
    
            if (Array.isArray(response.data) && response.data.data.length === 0) {
                setViewResult(false);
            } else {
                setUserCalculate(response.data.data);
                setViewResult(true);
            }
    
        } catch (error) {
            console.error("Error fetching user calculate:", error);
            setViewResult(false);
        }
    };
    

    const handleTestButtonClick = () => {
        navigate('/public/test-orientation');
    };

    const handleResultsButtonClick = () => {
        navigate('/orientation');
    };

    return (
        <LayoutNo>

            <Helmet>
                <title>Linkyways</title>
            </Helmet>
            <div className="md:flex hidden ">
            <div className=" grid grid-cols-1 md:grid-cols-2 gap-4  m-2 ">
                <div className="p-4">
                    <h2 className="text-2xl font-bold font-poppins text-[#1A3866] justify-start ">Qu’est-ce que l’IKIGAI ?</h2>
                    <p className="pt-2    text-[#141943] text-justify leading-6">
                        L'ikigai est un concept japonais qui se réfère à la raison de vivre, à ce qui donne un sens à notre existence, à ce qui nous motive et nous épanouit profondément.
                    </p>
                    <p className="pt-2    text-[#141943] text-justify leading-6">
                        Le terme "ikigai" est composé de deux mots japonais : "iki", qui signifie "vivre" ou "existence", et "gai", qui signifie "raison" ou "valeur". Ainsi, l'ikigai peut être compris comme "la raison de vivre" ou "la raison d'être".
                    </p>

                    <h2 className="text-2xl font-bold font-poppins text-[#1A3866] mb-4 mt-10">L’IKIGAI : une vie pleine de sens et d'harmonie</h2>
                    <p className="text-[#141943] leading-6 text-justify">L'ikigai se situe à l'intersection de ces quatre éléments.</p>
                    <p className="text-[#141943] leading-6 text-justify mt-2">Lorsque vous trouvez cette intersection, vous êtes en harmonie avec vous-même et avec le monde qui vous entoure, ce qui peut conduire à une vie pleine de sens, de satisfaction et de bonheur.</p>
                    {/*<hr className="h-1 py-2 text-black"/>*/}
                    <h2 className="text-2xl font-bold font-poppins text-[#1A3866] mb-4 mt-5 text-justify">Le test : Lien avec l'IKIGAI</h2>
                    <p className="text-[#141943] leading-6 text-justify">Le test d'orientation combine réflexion personnelle et puissance analytique. Il se compose de deux sections permettant de mieux cerner votre environnement de travail idéal, votre personnalité et vos sujets préférés.
                    </p>
                    <p className="text-[#141943] leading-6 text-justify">En analysant vos réponses, l’algorithme identifie pour vous les métiers qui vous correspondent le plus et qui répondent à un besoin réel.</p>
                    <p className="text-[#141943] leading-6 text-justify"> Passez le test pour augmenter vos chances de trouver votre voie !</p>
                    <div className="hidden md:flex xl:hidden  flex-col gap-2 mt-5">
                        <Button type="primary" className=" w-full font-bold font-poppins text-md gap-3 px-5 py-3 "
                                onClick={handleTestButtonClick}
                        >
                            {viewResult ? 'Repassez le test ' : 'Passez le test' }
                            <Handicon />

                        </Button>

                        {viewResult && (
                            <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 ">
                                Voir mes résultats
                            </Button>
                        )}
                    </div>
                </div>
                <div className=" p-4">
                    <div className="hidden md:flex  flex-col gap-2">
                        <Button type="primary" className=" w-full font-bold font-poppins text-md gap-3 px-5 py-3 "
                                onClick={handleTestButtonClick}
                        >
                            {viewResult ? 'Repassez le test ' : 'Passez le test' }
                            <Handicon />

                        </Button>

                        {viewResult && (
                            <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 ">
                                Voir mes résultats
                            </Button>
                        )}
                    </div>
                    <div className="flex xl:hidden justify-center item center my-3">
                        <img src={Igikai} alt="Description of image" className="object-cover md:w-8 xl:w-24rem p-3 " />
                    </div>



                    <div className="grid grid-cols-1  gap-3  mt-5 px-2">
                        <div className=" p-3 rounded-lg shadow-lg border-1 ">
                            <img src={RedHeart} alt="Description of image" className="p-1" />
                            <h3 className="text-sm mt-1 font-semibold font-poppins text-[#141943] "   >Ce que vous aimez </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont les activités, les intérêts ou les hobbies qui vous passionnent et vous apportent de la joie.</p>
                        </div>

                        <div className=" p-3 rounded-lg shadow-lg border-1 ">
                            <img src={Tool} alt="Description of image" className="p-1" />
                            <h3 className="text-sm  mt-1 font-semibold font-poppins text-[#141943]  " >Ce dans quoi vous êtes doué(e)  </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont vos compétences, vos talents naturels ou acquises, et les activités dans lesquelles vous excellez.</p>
                        </div>

                        <div className=" p-3 rounded-lg shadow-lg border-1 ">
                            <img src={Charity} alt="Description of image" className="p-1" />
                            <h3 className="text-sm mt-1 font-semibold font-poppins text-[#141943]  " >Ce dont le monde a besoin </h3>
                            <p className="mt-1   font-light text-[#141943]">Il s'agit des besoins de la société ou de votre communauté auxquels vous pouvez contribuer positivement.</p>
                        </div>

                        <div className=" p-3 rounded-lg shadow-lg border-1 ">
                            <img src={Money} alt="Description of image" className="p-1" />
                            <h3 className="text-sm font-semibold mt-1 font-poppins text-[#141943]  " > Ce pour quoi vous pouvez être rémunéré(e) </h3>
                            <p className="mt-1   font-light text-[#141943]">Ce sont les activités ou les métiers pour lesquels vous pouvez être rémunéré(e) et qui vous permettent de subvenir à vos besoins financiers.</p>
                        </div>
                    </div>


                    {/*<div className="flex lg:hidden pt-4  gap-2 justify-center  ">*/}
                    {/*    <Button type="primary" className="font-semibold text-md gap-3 w-full "*/}
                    {/*            onClick={handleTestButtonClick}*/}
                    {/*    >*/}
                    {/*        {viewResult ? 'Repassez le test ' : 'Passez le test' }*/}
                    {/*        <Handicon />*/}

                    {/*    </Button>*/}

                    {/*    {viewResult && (*/}
                    {/*        <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 w-full ">*/}
                    {/*            Voir mes résultats*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </div>
            </div>
            <div className="block md:hidden mt-6 p-4">
                <h2 className="text-2xl font-bold font-poppins text-[#1A3866] justify-start">
                    Qu’est-ce que l’IKIGAI ?
                </h2>
                <p className="pt-2 text-[#141943] text-justify leading-6">
                    L'ikigai est un concept japonais qui se réfère à la raison de vivre, à ce
                    qui donne un sens à notre existence, à ce qui nous motive et nous
                    épanouit profondément.
                </p>
                <p className="pt-2 text-[#141943] text-justify leading-6">
                    Le terme "ikigai" est composé de deux mots japonais : "iki", qui signifie
                    "vivre" ou "existence", et "gai", qui signifie "raison" ou "valeur". Ainsi,
                    l'ikigai peut être compris comme "la raison de vivre" ou "la raison
                    d'être".
                </p>

                <div className="flex justify-center items-center my-3">
                    <img src={Igikai} alt="Description of image" className="object-cover w-10 md:p-5  p-2" />
                </div>

                <h2 className="text-2xl font-bold font-poppins text-[#1A3866] mb-4 mt-10">L’IKIGAI : une vie pleine de sens et d'harmonie</h2>
                <p className="text-[#141943] leading-6 text-justify">L'ikigai se situe à l'intersection de ces quatre éléments.</p>
                <p className="text-[#141943] leading-6 text-justify mt-2">Lorsque vous trouvez cette intersection, vous êtes en harmonie avec vous-même et avec le monde qui vous entoure, ce qui peut conduire à une vie pleine de sens, de satisfaction et de bonheur.</p>
                {/*<hr className="h-1 py-2 text-black"/>*/}
                <h2 className="text-2xl font-bold font-poppins text-[#1A3866] mb-4 mt-5 text-justify">Le test : Lien avec l'IKIGAI</h2>
                <p className="text-[#141943] leading-6 text-justify">Le test d'orientation combine réflexion personnelle et puissance analytique. Il se compose de deux sections permettant de mieux cerner votre environnement de travail idéal, votre personnalité et vos sujets préférés.
                </p>
                <p className="text-[#141943] leading-6 text-justify">En analysant vos réponses, l’algorithme identifie pour vous les métiers qui vous correspondent le plus et qui répondent à un besoin réel.</p>
                <p className="text-[#141943] leading-6 text-justify"> Passez le test pour augmenter vos chances de trouver votre voie !</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5 mb-5">
                    <div className="p-3 rounded-lg shadow-lg border">
                        <img src={RedHeart} alt="Description of image" className="p-1" />
                        <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943]">
                            Ce que vous aimez
                        </h3>
                        <p className="mt-1 font-light text-[#141943]">
                            Ce sont les activités, les intérêts ou les hobbies qui vous passionnent
                            et vous apportent de la joie.
                        </p>
                    </div>
                    <div className="p-3 rounded-lg shadow-lg border">
                        <img src={Tool} alt="Description of image" className="p-1" />
                        <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943]">
                            Ce dans quoi vous êtes doué(e)
                        </h3>
                        <p className="mt-1 font-light text-[#141943]">
                            Ce sont vos compétences, vos talents naturels ou acquises, et les
                            activités dans lesquelles vous excellez.
                        </p>
                    </div>
                    <div className="p-3 rounded-lg shadow-lg border">
                        <img src={Charity} alt="Description of image" className="p-1" />
                        <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943]">
                            Ce dont le monde a besoin
                        </h3>
                        <p className="mt-1 font-light text-[#141943]">
                            Il s'agit des besoins de la société ou de votre communauté auxquels
                            vous pouvez contribuer positivement.
                        </p>
                    </div>
                    <div className="p-3 rounded-lg shadow-lg border">
                        <img src={Money} alt="Description of image" className="p-1" />
                        <h3 className="text-md mt-2 font-semibold font-poppins text-[#141943]">
                            Ce pour quoi vous pouvez être rémunéré(e)
                        </h3>
                        <p className="mt-1 font-light text-[#141943]">
                            Ce sont les activités ou les métiers pour lesquels vous pouvez être
                            rémunéré(e) et qui vous permettent de subvenir à vos besoins financiers.
                        </p>
                    </div>
                </div>

                <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-1 gap-2  flex justify-center items-center p-2">
                    <Button type="primary" className="font-semibold text-md gap-3 w-full mx-1 "
                            onClick={handleTestButtonClick}
                    >
                        {viewResult ? 'Repassez le test ' : 'Passez le test' }
                        <Handicon />

                    </Button>

                    {viewResult && (
                        <Button onClick={handleResultsButtonClick} className="font-semibold text-md gap-3 w-full ">
                            Voir mes résultats
                        </Button>
                    )}
                </div>
            </div>



        </LayoutNo>
    );
}

