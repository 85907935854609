import { useAxios } from './caller.service';

export const useMetierService = () => {
    const AxiosInstance = useAxios();

    let get35Professions = () => {
        return AxiosInstance.get('/35_professions/');
    }



    const searchProfessions = async (studyLevels, businessSectors ,seniorPosition , salaryRangeId,keywords,professionId,userId,page) => {
        let query = `/search_professions/?user_id=${userId}&page_number=${page}`;

        if (keywords) {
            query += `&keywords=${keywords}`;
        }

        if (seniorPosition !== undefined && seniorPosition !== "") {
            query += `&senior_position=${seniorPosition}`;
        }

        if (studyLevels) {
            query += `&studlevel_id=${studyLevels}`;
        }

        if (businessSectors) {
            query += `&business_sector_id=${businessSectors}`;
        }

        if (salaryRangeId) {
            query += `&salary_range_id=${salaryRangeId}`;
        }

        if (professionId) {
            query += `&profession_id=${professionId}`;
        }


        try {
            const response = await AxiosInstance.get(query);
            if (response.data.detail === "No Data Found") {
                return { success: false, message: "No data found.", data: [] };
            } else if (response.data.status === "success" && response.data.data) {
                const { total_count, total_pages, results } = response.data.data;
                return {
                    success: true,
                    message: "Data fetched successfully.",
                    data: {
                        total_count,
                        total_pages,
                        results
                    }
                };
            } else {
                return { success: false, message: "Unexpected response structure.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, message: "Error fetching data.", data: [] };
        }
    };

    const get35aProfessionsDescription = async (professionId, userId) => {
        if (!professionId) {
            return Promise.reject('Profession ID is required');
        }

        try {
            // Build query parameters
            const params = { profession_id: professionId };
            if (userId) {
                params.user_id = userId; // Include user_id if provided
            }

            const response = await AxiosInstance.get('/35a_professions_description/', {
                params: params // Pass query parameters
            });

            // Check if the response is successful
            if (response.data.status === 'success') {
                return response.data; // Return the full response data
            } else {
                throw new Error(response.data.message || 'Failed to fetch profession details');
            }
        } catch (error) {
            console.error("Error fetching profession description:", error);
            throw new Error(error.response ? error.response.data.message : error.message);
        }
    };

    const getProfessionsPublic = () => {
        return AxiosInstance.get('/getfirst_professions_public/')
            .then(response => {
                if (response.data.status === "success") {
                    const { results } = response.data.data;
                    return {
                        success: true,
                        message: response.data.message,
                        data: results
                    };
                } else {
                    return {
                        success: false,
                        message: response.data.message || "Unexpected response structure.",
                        data: []
                    };
                }
            })
            .catch(error => {
                console.error("Error fetching first 16 public items:", error);
                return {
                    success: false,
                    message: "Error fetching first 16 public items.",
                    data: []
                };
            });
    };

    return {
        get35Professions,
        searchProfessions,
        get35aProfessionsDescription,
        getProfessionsPublic
    }
}
