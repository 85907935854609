import React, { useState } from 'react';
import { Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import { useSubmissionService } from '../../../services/submission.service';
import { useAuth } from '../../../services/auth.provider';
import { useAuthService } from '../../../services/auth.service';
import { notification } from 'antd';
import { CryptoService } from '../../../services/crypte.service';
import { encryptWithPublicKey } from '../../../services/rsa.service';


export function VerificationModal({ email, password,selectedUserName,
                                      selectedUserStatus,
                                      selectedStudyLevel,
                                      selectedBacType,
                                      selectedSpecializations,
                                      selectedAreaStudies,
                                      handlePrevious
                                  }) {  // Add onChangeEmail prop
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [showAlert, setShowAlert] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const navigate = useNavigate();
    const { signUp } = useAuth();
    const { confirmEmail, checkEmail } = useAuthService();;
    const { userSubmission } = useSubmissionService();
    const questionsScoresData = JSON.parse(localStorage.getItem('questionsScores')) || {};
    const subjectsLevelData = JSON.parse(localStorage.getItem('ratings')) || {};


    const handleOtpChange = (index, value) => {
        if (value.match(/^[0-9]$/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowAlert(false);

        if (otp.some(digit => digit === '') || otp.length !== 6) {
            setShowAlert(true);
            return;
        }

        const otpCode = otp.join('');
        try {
            const response = await confirmEmail(otpCode, email);
            if (response.data.status === 'success') {

                let encryptedPassword = encryptWithPublicKey(password);


                await signUp(email, encryptedPassword, null,selectedUserName,selectedUserStatus,selectedStudyLevel,selectedBacType,selectedSpecializations,selectedAreaStudies).then(response => {
                    if (response.data.status === 'success') {
                        console.log("sign up email ",email)
                        setShowAlert(false);
                        setSuccessVisible(true);
                        console.log("questionsScoresData1",questionsScoresData)
                        console.log("subjectsLevelData1",subjectsLevelData)
                    } else {
                        setShowAlert(true);
                        setSuccessVisible(false);
                        return;
                    }
                })


                setTimeout(() => {
                    setSuccessVisible(false);
                    console.log("questionsScoresData2",questionsScoresData)
                    console.log("subjectsLevelData2",subjectsLevelData)
                    if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                        navigate("/orientation");
                    } else {
                        navigate("/formations");
                    }
                }, 3000);

                if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                    const userId = localStorage.getItem('user_id');
                    console.log("user sub userid",userId)
                    if (userId) {
                        const decryptedId = CryptoService.decryptData(userId)
                        // console.log(decryptedId, "decryptedId");
                        userSubmission(decryptedId);
                        localStorage.removeItem('questionsScores');
                        localStorage.removeItem('ratings');
                    } else {
                        console.warn("No user ID found in local storage");
                    }
                }
                localStorage.removeItem('stepData');
                localStorage.removeItem('email-signup')
                //onHide();
            } else {
                setShowAlert(true);
            }
        } catch (error) {
            console.error("OTP verification failed:", error);
            setShowAlert(true);
        }
    };

    const handleResendOtp = async () => {
        try {
            setShowAlert(false);
            // Call your service to resend the OTP here
            await checkEmail(email, null, 0);

            // Success notification
            notification.success({
                message: 'Code de vérification renvoyé',
                description: 'Le code de vérification a été renvoyé avec succès à votre email.',
                placement: 'topRight',
            });
        } catch (error) {
            // Error notification
            notification.error({
                message: 'Erreur lors de l\'envoi',
                description: 'Une erreur est survenue lors de la réinitialisation du code de vérification.',
                placement: 'topRight',
            });
            console.error("Error resending OTP:", error);
        }
    };


    const handleChangeEmail = () => {
        localStorage.setItem('email-signup', email)
        handlePrevious();
        //onHide();
    };

    return (
        <>
            <div className="text-center px-3" >
                <p className="text-md font-semibold text-[#141943E5] mb-4 ">
                    Un code à 6 chiffres a été envoyé à <span className="text-[#1681FF]">{email}</span> . Entrez-le ci-dessous pour confirmer.
                </p>

                {showAlert && (
                    <div className="bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-2 mb-3" role="alert">
                        Le code OTP entré est incorrect. Veuillez essayer à nouveau.
                    </div>
                )}

                <form onSubmit={handleSubmit}>

                    <div className="flex justify-center gap-2 sm:gap-3">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                className="w-10 h-20  text-center text-2xl bg-slate-100 border-1 rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        ))}
                    </div>

                    <button
                        type="submit"
                        className="mt-6 w-full bg-[#1681FF] hover:bg-blue-600 transition-colors text-white py-2 px-4 rounded-lg font-poppins font-bold"
                    >
                        Valider
                    </button>
                </form>

                <div className="text-sm text-slate-500 mt-4">
                    Vous n'avez pas reçu le code ? Vérifiez dans vos Spams, <a className="font-medium text-[#1681FF] hover:text-blue-600" href="#0" onClick={handleResendOtp}>Renvoyer</a>{' '}le code
                    ou <a className="font-medium text-[#1681FF] hover:text-blue-600" href="#0" onClick={handleChangeEmail}>Changer</a>{' '}l'adresse email
                </div>

            </div>

            {/* Success Modal */}
            <Modal
                open={successVisible}
                footer={null} // Removes footer buttons
                closable={false} // Disables the close button
                maskClosable={false} // Disables closing by clicking outside the modal
                centered // Centers the modal on the screen
                style={{
                    width: '90%',
                    maxWidth: '400px',
                    padding: '2rem',
                    borderRadius: '0.5rem',
                    textAlign: 'center',
                }}
                bodyStyle={{
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    padding: '1rem',
                }}
            >
                <h2 className="text-xl font-semibold mb-4">Félicitations!</h2>
                <p className="text-sm text-gray-600 mb-4">
                    Votre compte Linkyways est désormais activé. Préparez-vous, vous allez être redirigé vers votre prochaine aventure...
                </p>
            </Modal>
        </>
    );
}

// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { useNavigate } from "react-router-dom";
// import { useSubmissionService } from '../../../services/submission.service';
// import { useAuth } from '../../../services/auth.provider';
// import { useAuthService } from '../../../services/auth.service';
// import { notification } from 'antd';
// import { CryptoService } from '../../../services/crypte.service';
// import { encryptWithPublicKey } from '../../../services/rsa.service';
//
// Modal.setAppElement('#root');
//
// export function VerificationModal({ email, password, visible, onHide }) {  // Add onChangeEmail prop
//     const [otp, setOtp] = useState(new Array(6).fill(''));
//     const [showAlert, setShowAlert] = useState(false);
//     const [successVisible, setSuccessVisible] = useState(false);
//     const navigate = useNavigate();
//     const { signUp } = useAuth();
//     const { confirmEmail, checkEmail } = useAuthService();;
//     const { userSubmission } = useSubmissionService();
//
//     const handleOtpChange = (index, value) => {
//         if (value.match(/^[0-9]$/)) {
//             const newOtp = [...otp];
//             newOtp[index] = value;
//             setOtp(newOtp);
//             if (index < 5) {
//                 document.getElementById(`otp-input-${index + 1}`).focus();
//             }
//         }
//     };
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setShowAlert(false);
//
//         if (otp.some(digit => digit === '') || otp.length !== 6) {
//             setShowAlert(true);
//             return;
//         }
//
//         const otpCode = otp.join('');
//         try {
//             const response = await confirmEmail(otpCode, email);
//             if (response.data.status === 'success') {
//
//                 let encryptedPassword = encryptWithPublicKey(password);
//
//                 await signUp(email, encryptedPassword, null).then(response => {
//                     if (response.data.status === 'success') {
//                         setShowAlert(false);
//                         setSuccessVisible(true);
//                     } else {
//                         setShowAlert(true);
//                         setSuccessVisible(false);
//                         return;
//                     }
//                 })
//
//                 const questionsScoresData = JSON.parse(localStorage.getItem('questionsScores')) || {};
//                 const subjectsLevelData = JSON.parse(localStorage.getItem('ratings')) || {};
//
//                 setTimeout(() => {
//                     setSuccessVisible(false);
//                     if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
//                         navigate("/orientation");
//                     } else {
//                         navigate("/");
//                     }
//                 }, 3000);
//
//                 if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
//                     const userId = localStorage.getItem('user_id');
//                     if (userId) {
//                         const decryptedId = CryptoService.decryptData(userId)
//                         // console.log(decryptedId, "decryptedId");
//                         userSubmission(decryptedId);
//                         localStorage.removeItem('questionsScores');
//                         localStorage.removeItem('ratings');
//                     } else {
//                         console.warn("No user ID found in local storage");
//                     }
//                 }
//                 localStorage.removeItem('stepData');
//                 localStorage.removeItem('email-signup')
//                 onHide();
//             } else {
//                 setShowAlert(true);
//             }
//         } catch (error) {
//             console.error("OTP verification failed:", error);
//             setShowAlert(true);
//         }
//     };
//
//     const handleResendOtp = async () => {
//         try {
//             setShowAlert(false);
//             // Call your service to resend the OTP here
//             await checkEmail(email, null, 0);
//
//             // Success notification
//             notification.success({
//                 message: 'Code de vérification renvoyé',
//                 description: 'Le code de vérification a été renvoyé avec succès à votre email.',
//                 placement: 'topRight',
//             });
//         } catch (error) {
//             // Error notification
//             notification.error({
//                 message: 'Erreur lors de l\'envoi',
//                 description: 'Une erreur est survenue lors de la réinitialisation du code de vérification.',
//                 placement: 'topRight',
//             });
//             console.error("Error resending OTP:", error);
//         }
//     };
//
//
//     const handleChangeEmail = () => {
//         localStorage.setItem('email-signup', email)
//         onHide();
//     };
//
//     return (
//         <>
//             <Modal
//                 isOpen={visible && !successVisible}  // Hide OTP modal when success modal is visible
//                 onRequestClose={null}  // Disable the default close behavior
//                 shouldCloseOnOverlayClick={false}  // Disable closing by clicking outside
//                 shouldCloseOnEsc={false} // Disable closing with Esc key
//                 style={{
//                     overlay: {
//                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                     },
//                     content: {
//                         top: '50%',
//                         left: '50%',
//                         right: 'auto',
//                         bottom: 'auto',
//                         transform: 'translate(-50%, -50%)',
//                         width: '90%',
//                         maxWidth: '400px',
//                         padding: '2rem',
//                         borderRadius: '0.5rem',
//                         backgroundColor: 'white',
//                         textAlign: 'center',
//                         boxSizing: 'border-box',
//                     },
//                 }}
//             >
//                 <h2 className="text-xl font-semibold mb-4">Vérification de votre compte</h2>
//
//                 <p className="text-sm text-gray-600 mb-4">
//                     Un code de vérification a été envoyé à l'adresse email suivante : <span className="text-[#1681FF]">{email}</span>
//                 </p>
//
//                 {showAlert && (
//                     <div className="bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-2 mb-3" role="alert">
//                         Le code OTP entré est incorrect. Veuillez essayer à nouveau.
//                     </div>
//                 )}
//
//                 <form onSubmit={handleSubmit}>
//                     <p className="text-sm text-gray-600  mb-4">
//                         Veuillez entrer le code de vérification à 6 chiffres envoyé à votre boîte email.
//                     </p>
//
//                     <div className="flex justify-center gap-2 sm:gap-3">
//                         {otp.map((digit, index) => (
//                             <input
//                                 key={index}
//                                 id={`otp-input-${index}`}
//                                 type="text"
//                                 maxLength="1"
//                                 onChange={(e) => handleOtpChange(index, e.target.value)}
//                                 className="w-10 h-12 sm:w-12 sm:h-14 text-center text-2xl bg-slate-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                             />
//                         ))}
//                     </div>
//
//                     <button
//                         type="submit"
//                         className="mt-6 w-full bg-[#1681FF] hover:bg-blue-600 transition-colors text-white py-2 px-4 rounded-lg"
//                     >
//                         Vérifier mon compte
//                     </button>
//                 </form>
//
//                 <div className="text-sm text-slate-500 mt-4">
//                     Vous n'avez pas reçu le code ? Vérifiez dans vos Spams, <a className="font-medium text-[#1681FF] hover:text-blue-600" href="#0" onClick={handleResendOtp}>Renvoyer</a>{' '}le code
//                      ou <a className="font-medium text-[#1681FF] hover:text-blue-600" href="#0" onClick={handleChangeEmail}>Changer</a>{' '}l'adresse email
//                 </div>
//
//                 {/* <div className="text-sm text-slate-500 mt-4">
//         Vous avez entré une mauvaise adresse email ? <a className="font-medium text-red-500 hover:text-red-600" href="#0" onClick={handleChangeEmail}>Changer mon adresse email</a>
//     </div> */}
//             </Modal>
//
//             {/* Success Modal */}
//             <Modal
//                 isOpen={successVisible}
//                 onRequestClose={null}  // Disable the default close behavior
//                 shouldCloseOnOverlayClick={false}  // Disable closing by clicking outside
//                 shouldCloseOnEsc={false} // Disable closing with Esc key
//                 style={{
//                     overlay: {
//                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                     },
//                     content: {
//                         top: '50%',
//                         left: '50%',
//                         right: 'auto',
//                         bottom: 'auto',
//                         transform: 'translate(-50%, -50%)',
//                         width: '90%',
//                         maxWidth: '400px',
//                         padding: '2rem',
//                         borderRadius: '0.5rem',
//                         backgroundColor: 'white',
//                         textAlign: 'center',
//                         boxSizing: 'border-box',
//                     },
//                 }}
//             >
//                 <h2 className="text-xl font-semibold mb-4">Félicitations!</h2>
//                 <p className="text-sm text-gray-600 mb-4">
//                     Votre compte Linkyways est désormais activé. Préparez-vous, vous allez être redirigé vers votre prochaine aventure...
//                 </p>
//             </Modal>
//         </>
//     );
// }
