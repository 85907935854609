import React, { useEffect, useState } from "react";
import TraitsDeCaractere from "../dashboardComponents/TraitsDeCaractere";
import Categories from "../dashboardComponents/Categories";
import TabContent from "../utils/TabContent";
import LayoutNo from "../utils/LayoutNo";
import { useUserService } from "../services/userforms.service";
import { CryptoService } from "../services/crypte.service";
import { useNavigate,Link } from 'react-router-dom';
import { Collapse } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Alert, Carousel } from "antd";
import {Helmet} from "react-helmet";


export default function Guidance() {
    const { Panel } = Collapse;
    const [activeTab, setActiveTab] = useState(2);
    const [profileDescription, setProfileDescription] = useState("");
    const [userTraits, setUserTrait] = useState([]);
    const [userCategories, setUserCategories] = useState([]);
    const [userMetiers, setUserMetiers] = useState([]);

    const decryptedId = CryptoService.decryptData(localStorage.getItem('user_id'))
    const [userId] = useState(() => decryptedId);
    const navigate = useNavigate();
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    const { getUserAiResult } = useUserService();
    const [isLoading, setIsLoading] = useState(true);

    const handleNavigate = () => {
        navigate('/public/test-orientation');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        //console.log("user iiiiid" , userId)

        if (userId) {
            setIsLoading(true);
            getUserAiResult(userId)
                .then((response) => {
                    if (response.data.status === "success" && response.data.data.traits.length > 0) {
                        setProfileDescription(response.data.data.profile_description || "Description not available.");
                        setUserTrait(response.data.data.traits);
                        setUserCategories(response.data.data.user_subjects)
                        setUserMetiers(response.data.data.metiers)
                    } else {
                        console.error("Unexpected response format or empty personality data:", response.data);
                        setProfileDescription("Description not available.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user submission data:", error);
                    setProfileDescription("Error fetching profile description.");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [userId]);


    return (

        // <LayoutNo>
        //     <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[74%_26%]  mt-1 bg-gray-100 ">
        //
        //         <div className="overflow-y-auto h-full md:mt-2 mt-6">
        //             {profileDescription ? (
        //                 <div className="card shadow-sm bg-white border-1 rounded-lg mt-2  md:ml-3 ml-3 md:mr-2 mr-3">
        //                     <Collapse
        //                         defaultActiveKey={[]}
        //                         onChange={() => setIsDescriptionVisible(!isDescriptionVisible)}
        //                         expandIconPosition="end"
        //                         expandIcon={({ isActive }) =>
        //                             isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
        //                         }
        //                     >
        //                         <Panel
        //                             header={<span style={{ fontWeight: 'bold', color: '#000' }}>Votre Profil</span>}
        //                             key="1"
        //                         >
        //                             <p className="font-light text-sm text-[#141943]">
        //                                 {isDescriptionVisible ? profileDescription : ''}
        //                             </p>
        //                         </Panel>
        //                     </Collapse>
        //                 </div>
        //             ) : (
        //                 <div className="  card shadow-sm bg-white  border-1 border-blue-100 rounded-lg mt-2 p-3 mx-3 animate-pulse">
        //                     <div className="h-2 bg-gray-200 rounded w-1/4 "></div>
        //                     <div className="h-2 bg-gray-200 rounded w-full mt-2"></div>
        //                 </div>
        //             )}
        //             {/* Sidebar content for small screens */}
        //             <div className="lg:hidden md:block   ml-3 mr-3 mt-2">
        //                 <Carousel autoplay autoplaySpeed={2000} dots={false} arrows={false} adaptiveHeight={true} draggable={true} speed={1000}>
        //                     <div className="bg-white border-2 border-gray-300 shadow-sm rounded-lg p-2 h-full flex flex-col">
        //                         <h2 className="text-md font-bold -mb-2 px-3">Traits de caractères</h2>
        //                         <TraitsDeCaractere traitList={userTraits} />
        //                     </div>
        //                     <div className="bg-white border-2 border-gray-300 shadow-sm rounded-lg px-2 py-5 h-full flex flex-col">
        //                         <Categories categoriesList={userCategories} />
        //                     </div>
        //                 </Carousel>
        //             </div>
        //
        //             {/* Tab Content */}
        //             <div className="md:pl-3 pl-3 md:pr-2 pr-3 mt-2 mb-14">
        //                 <div className="flex border-b-0 border-white">
        //                     <button
        //                         className={`py-2 px-4 text-sm text-[#141943] ${activeTab === 1 ? 'border-2 border-white bg-white border-b-0  rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
        //                         onClick={() => setActiveTab(1)}
        //                     >
        //                         Métiers
        //                     </button>
        //                     <button
        //                         className={`py-2 px-4 text-sm  text-[#141943] ${activeTab === 2 ? 'border-2 border-white bg-white border-b-0  rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
        //                         onClick={() => setActiveTab(2)}
        //                     >
        //                         Formations
        //                     </button>
        //                 </div>
        //                 <div className="mt-1 ">
        //                     {activeTab === 1 && (
        //                         <TabContent
        //                             metiers={userMetiers}
        //                             userId={userId}
        //                         />
        //                     )}
        //                     {activeTab === 2 && (
        //                         <div className="mt-5 flex justify-center">
        //                             <Alert
        //                                 message="Bientôt disponible."
        //                                 type="info"
        //                                 showIcon
        //                                 style={{ width: "100%", marginBottom: '16px' }}
        //                             />
        //                         </div>
        //                     )}
        //                 </div>
        //             </div>
        //         </div>
        //
        //         <div >
        //             <aside className="lg:flex flex-col hidden  w-full pr-3     ">
        //
        //
        //                 <div className="bg-white shadow-lg rounded-xl  mt-3  w-full py-3">
        //                     <Categories categoriesList={userCategories} />
        //                 </div>
        //                 <div className="bg-white shadow-lg rounded-xl mt-2  w-full py-3">
        //                     <h2 className="text-md font-poppins font-bold mb-1 mt-2 px-3  text-[#141943]">Traits de caractères</h2>
        //                     <TraitsDeCaractere traitList={userTraits} />
        //                 </div>
        //                 <div className="flex justify-end  rounded-lg mt-4 w-full mt-2 ">
        //                     <Link to={"/igikai"}>
        //
        //
        //                         <p className="text-sm text-[#141943] font-poppins   ">
        //                             Qu’est-ce que l’IKIGAI ?
        //                         </p>
        //                     </Link>
        //                 </div>
        //             </aside>
        //         </div>
        //     </div>
        //
        //     {/* Fixed button for small screens */}
        //     <div
        //         className="lg:hidden fixed bottom-0 left-0 right-0   bg-[#E3EDF7] border-t border-gray-300 shadow-lg p-2 ">
        //         <div className="flex justify-center ">
        //             <button
        //                 onClick={handleNavigate}
        //                 className="w-full bg-[#1681FF] p-2 border-1 rounded-md text-white font-bold text-ms cursor-pointer">Repasser le
        //                 test
        //             </button>
        //         </div>
        //     </div>
        //
        // </LayoutNo>

        <LayoutNo>
            <Helmet>
                <title>Orientation</title>
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[74%_26%] mt-1 bg-gray-100">
                <div className="overflow-y-auto h-full md:mt-2 mt-6">
                    {/* Profile Description Section */}
                    {profileDescription ? (
                        <div className="card  hidden md:flex shadow-sm bg-white border-1 rounded-lg mt-2  md:ml-3 ml-3 md:mr-2 mr-3">
                            <Collapse
                                defaultActiveKey={[]}
                                onChange={() => setIsDescriptionVisible(!isDescriptionVisible)}
                                expandIconPosition="end"
                                className="w-full"
                                expandIcon={({ isActive }) =>
                                    isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
                                }
                            >
                                <Panel
                                    header={<span style={{ fontWeight: 'bold', color: '#000' }}>Votre Profil</span>}
                                    key="1"
                                >
                                    <p className="font-light text-sm text-[#141943]">
                                        {isDescriptionVisible ? profileDescription : ''}
                                    </p>
                                </Panel>
                            </Collapse>
                        </div>
                    ): (
                                        <div className="  card shadow-sm bg-white  border-1 border-blue-100 rounded-lg mt-2 p-3 mx-3 animate-pulse">
                                            <div className="h-2 bg-gray-200 rounded w-1/4 "></div>
                                            <div className="h-2 bg-gray-200 rounded w-full mt-2"></div>
                                        </div>
                                    )}

                    {/* Tab Content */}
                    <div className="md:pl-3 pl-3 md:pr-2 pr-3 mt-2 mb-14">
                        <div className="flex border-b-0 border-white">
                            <button
                                className={` py-2 px-4 text-sm text-[#141943] md:hidden ${activeTab === 1 ? 'border-2 border-white bg-white border-b-0 rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
                                onClick={() => setActiveTab(1)}
                            >
                                Profile
                            </button>
                            <button
                                className={`py-2 px-4 text-sm text-[#141943] ${activeTab === 2 ? 'border-2 border-white bg-white border-b-0 rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
                                onClick={() => setActiveTab(2)}
                            >
                                Métiers
                            </button>
                            {/* Profile Tab - Only shows on small screens */}
                            <button
                                className={`py-2 px-4 text-sm text-[#141943] ${activeTab === 3 ? 'border-2 border-white bg-white border-b-0 rounded-t-lg shadow-lg font-bold' : 'font-semibold'} focus:outline-none`}
                                onClick={() => setActiveTab(3)}
                            >
                                Formations
                            </button>
                        </div>

                        <div className="mt-1">
                            {activeTab === 2 && <TabContent metiers={userMetiers} userId={userId} />}
                            {activeTab === 3 && (
                                <div className="mt-5 flex justify-center">
                                    <Alert
                                        message="Bientôt disponible."
                                        type="info"
                                        showIcon
                                        style={{ width: "100%", marginBottom: '16px' }}
                                    />
                                </div>
                            )}
                            {activeTab === 1 && (
                                <div className="mt-1">
                                    {profileDescription && (
                                        <div className="card bg-white p-2 border-2 shadow-md rounded-lg ">
                                            <p className="font-light text-sm text-[#141943]">
                                                {profileDescription}
                                            </p>
                                        </div>

                                    )}
                                    <div className="mt-2">
                                        <div className="flex flex-col gap-2">
                                            <div className="bg-white border-2 border-gray-300 shadow-sm rounded-lg p-2 h-full flex flex-col">
                                                <h2 className="text-md font-bold -mb-2 px-3">Traits de caractères</h2>
                                                <TraitsDeCaractere traitList={userTraits} />
                                            </div>
                                            <div className="bg-white border-2 border-gray-300 shadow-sm rounded-lg px-2 py-5 h-full flex flex-col">
                                                <Categories categoriesList={userCategories} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Sidebar for Large Screens */}
                <div>
                    <aside className="lg:flex flex-col hidden w-full pr-3">
                        <div className="bg-white shadow-lg rounded-xl mt-3 w-full py-3">
                            <Categories categoriesList={userCategories} />
                        </div>
                        <div className="bg-white shadow-lg rounded-xl mt-2 w-full py-3">
                            <h2 className="text-md font-poppins font-bold mb-1 mt-2 px-3 text-[#141943]">Traits de caractères</h2>
                            <TraitsDeCaractere traitList={userTraits} />
                        </div>
                        <div className="flex justify-end rounded-lg mt-4 w-full mt-2">
                            <Link to={"/ikigai"}>
                                <p className="text-sm text-[#141943] font-poppins">Qu’est-ce que l’IKIGAI ?</p>
                            </Link>
                        </div>
                    </aside>
                </div>
            </div>
            {/* Fixed button for small screens */}
            <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-[#E3EDF7] border-t border-gray-300 shadow-lg p-2">
                <div className="flex justify-between gap-2">
                    <button
                        onClick={handleNavigate}
                        className="flex-1 bg-white py-2 border-1 rounded-md text-blue-500 font-bold border-blue-500 cursor-pointer ">
                        Repasser le test
                    </button>
                        <button
                            className="flex-1 bg-[#1681FF] p-2 border-1 rounded-md text-white font-bold cursor-pointer ">
                            <Link to={'/public/test-orientation/sujets'}>
                            Modifier les sujets
                            </Link>
                        </button>
                    {/*</Link>*/}
                </div>
            </div>

        </LayoutNo>

    );
}