
const GoogleIcon = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_201_1103)">
                <path d="M19.1749 9.71058C19.1749 8.93171 19.1131 8.36334 18.9792 7.77393H10.2734V11.2894H15.3835C15.2805 12.163 14.7242 13.4787 13.4878 14.3628L13.4705 14.4805L16.2231 16.659L16.4138 16.6784C18.1652 15.0259 19.1749 12.5945 19.1749 9.71058Z" fill="#4285F4"/>
                <path d="M10.2717 18.9729C12.7752 18.9729 14.8769 18.1308 16.412 16.6783L13.4861 14.3627C12.7031 14.9205 11.6522 15.31 10.2717 15.31C7.81963 15.31 5.73851 13.6575 4.99664 11.3735L4.8879 11.383L2.02571 13.6459L1.98828 13.7522C3.51306 16.8467 6.64508 18.9729 10.2717 18.9729Z" fill="#34A853"/>
                <path d="M4.9974 11.3736C4.80165 10.7842 4.68836 10.1526 4.68836 9.50004C4.68836 8.84742 4.80165 8.21592 4.9871 7.62651L4.98191 7.50098L2.08385 5.20166L1.98903 5.24774C1.3606 6.53185 1 7.97386 1 9.50004C1 11.0262 1.3606 12.4682 1.98903 13.7523L4.9974 11.3736Z" fill="#FBBC05"/>
                <path d="M10.2717 3.68991C12.0128 3.68991 13.1873 4.45826 13.857 5.10036L16.4738 2.49004C14.8667 0.963864 12.7752 0.0270996 10.2717 0.0270996C6.64508 0.0270996 3.51306 2.15321 1.98828 5.24766L4.98635 7.62643C5.73851 5.34242 7.81963 3.68991 10.2717 3.68991Z" fill="#EB4335"/>
            </g>
            <defs>
                <clipPath id="clip0_201_1103">
                    <rect x="1" width="18.1739" height="19" rx="9.08696" fill="white"/>
                </clipPath>
            </defs>
        </svg>



    )
}
export default GoogleIcon;