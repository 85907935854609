
import React, { useState, useEffect } from "react";
import MultiCustomSelect from "./dropDown/MultiCustomSelect";
import { useFormationService } from "../services/formation.service";
import {useAuth} from "../services/auth.provider";
import {useUserService} from "../services/userforms.service";

export default function MetiersFilters({
                                             onSelectStudLevel,
                                             clearFilters,
                                             onSelectBusiessSector,
                                             selectedStudLevelIds,
                                             selectedBusinessIds
                                         }) {
    const { getStudiesLevel, getBusinessSector } = useUserService();


    const [initialStudLevelSelected, setInitialTrainingSelected] = useState([]);
    const [initialAreaSelected, setInitialAreaSelected] = useState([]);
    const { isAuthenticated } = useAuth();
    const [studyLevels, setStudyLevels] = useState([]);
    const [businessSector, setBusinessSectors] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [studyResponse, BusinessSectorResponse] = await Promise.all([
                    getStudiesLevel(0, 1),
                    getBusinessSector(),
                ]);

                setStudyLevels(studyResponse.data.data);
                setBusinessSectors(BusinessSectorResponse.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (studyLevels?.length > 0 && selectedStudLevelIds?.length > 0) {
            const selected = studyLevels.filter(option =>
                selectedStudLevelIds.includes(option.studlevel_id)
            );
            setInitialTrainingSelected(selected);
        } else {
            setInitialTrainingSelected([]);
        }
    }, [studyLevels, selectedStudLevelIds]);

    useEffect(() => {
        if (businessSector?.length > 0 && selectedBusinessIds?.length > 0) {
            const selected = businessSector.filter(option =>
                selectedBusinessIds.includes(option.business_sect_id)
            );
            setInitialAreaSelected(selected);
        } else {
            setInitialAreaSelected([]);
        }
    }, [businessSector, selectedBusinessIds]);



    const handleStudLevelChange = (selectedTraining) => {
        //console.log("Selected training:", selectedTraining);
        onSelectStudLevel(selectedTraining);
        if (isAuthenticated) {
            onSelectStudLevel(selectedTraining);
        }
    };

    const handleBusinessSectorChange = (selectedBusiness) => {
        //console.log("Selected business:", selectedBusiness);
        if (isAuthenticated) {
            onSelectBusiessSector(selectedBusiness);
        }
    };




    return (
        <div  >
            <div className="w-full flex flex-wrap justify-between items-center py-2">

                {/* Dropdowns */}
                <div className="flex flex-wrap gap-2 w-full lg:w-auto">
                    {/* Type de formation */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            placeholder="Niveau d'études"
                            options={studyLevels}
                            onChange={handleStudLevelChange}
                            initialSelectedOptions={initialStudLevelSelected}
                            optionKey="studlevel_id"
                            optionLabel="study_level"
                            width={"155px"}
                            clearFilters={clearFilters}
                        />
                    </div>

                    {/* Filière */}
                    <div className="w-full sm:w-1/2 lg:w-auto">
                        <MultiCustomSelect
                            options={businessSector}
                            onChange={handleBusinessSectorChange}
                            initialSelectedOptions={initialAreaSelected}
                            optionKey="business_sect_id"
                            optionLabel="business_sect"
                            placeholder="Secteur d'activité"
                            width={"165px"}
                            clearFilters={clearFilters}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}
