// import React from 'react';
// import { Modal, Button } from 'antd';
// import RatingComponent from '../RatingComponent';
// import trashIcon from '../../images/modal/trashicon.svg';
//
// const RatingModal = ({ visible, item, rating, onClose, onRatingChange, onDelete }) => (
//     <Modal
//         visible={visible}
//         onCancel={onClose}
//         className=" p-2   justify-center"
//         footer={ <div className="flex justify-end">
//             <img
//                 src={trashIcon}
//                 alt="Delete"
//                 className="ml-1 cursor-pointer mr-2 block lg:hidden border-1 border-blue-500 px-4 py-2 rounded-md"
//                 onClick={onDelete}
//                 aria-label="Delete item"
//             />
//         </div>}
//         centered
//     >
//         {/* Content */}
//         <div>
//             <div className="w-full flex justify-center">
//                 <h2 className="text-xl font-semibold mb-2">
//                     {item ? item.label : 'No Item Selected'}
//                 </h2>
//             </div>
//             <p className="w-full flex justify-center text-sm mb-4">Quel est votre niveau d'intérêt pour ce sujet ?</p>
//             <div className="flex justify-center mb-4">
//                 <RatingComponent
//                     ratingValue={rating}
//                     maxRating={3}
//                     starColor="text-yellow-400"
//                     inactiveStarColor="text-gray-300"
//                     //size="w-8 h-8"
//                     onRatingChange={onRatingChange}
//                 />
//             </div>
//         </div>
//
//
//     </Modal>
// );
//
// export default RatingModal;
import React, { useEffect, useState } from 'react';
import { Modal, Rate } from 'antd';
import trashIcon from '../../images/modal/trashicon.svg';

const RatingModal = ({ visible, item, rating, onClose, onRatingChange, onDelete }) => {
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        if (rating > 0) {
            const timer = setTimeout(() => setShowDelete(true), 500);
            return () => clearTimeout(timer);
        } else {
            setShowDelete(false);
        }
    }, [rating]);

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            className="p-2 justify-center"
            footer={
                showDelete && ( // Only show the delete button after 5 seconds
                    <div className="flex justify-end">
                        <img
                            src={trashIcon}
                            alt="Delete"
                            className="ml-1 cursor-pointer mr-2 block lg:hidden border border-blue-500 px-4 py-2 rounded-md"
                            onClick={onDelete}
                            aria-label="Delete item"
                        />
                    </div>
                )
            }
            centered
        >
            {/* Content */}
            <div>
                <div className="w-full flex justify-center">
                    <h2 className="text-xl font-semibold mb-2">
                        {item ? item.label : 'No Item Selected'}
                    </h2>
                </div>
                <p className="w-full flex justify-center text-sm mb-4">
                    Quel est votre niveau d'intérêt pour ce sujet ?
                </p>
                <div className="flex justify-center mb-4">
                    <Rate
                        count={3} // Max rating of 3
                        value={rating} // Current rating value
                        onChange={onRatingChange} // Triggered when a rating is selected
                        allowClear // Allow clearing the rating
                        style={{
                            fontSize: '2rem', // Adjust star size
                            color: '#fadb14' // Star color (Ant Design's yellow)
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default RatingModal;
