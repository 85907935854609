import React from 'react';
import UserModal from "./inscriptionProcess/inscriptionSteps";
import logo from "../images/logo.svg";
import {Link} from "react-router-dom";

export default function Registration() {
    return (
        <div
            className="bg-white"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <UserModal />
        </div>
    );
}
